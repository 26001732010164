import {Address, BaseModel, Purchase} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';

export default class PurchaseSupplier extends BaseModel {
  constructor({id, number, purchase = undefined, supplier = undefined}) {
    super(id, number, '');
    this.purchase = purchase ? new Purchase(purchase) : null;
    this.supplier = supplier ? new Address(supplier) : null;
  }

  static get fkName() {
    return null;
  }
  static get apiRoute() {
    return 'purchase_supplier';
  }
  static get detailPageRoute() {
    return undefined;
  }
  static get listPageRoute() {
    return undefined;
  }
  get fkName() {
    return PurchaseSupplier.fkName;
  }
  get apiRoute() {
    return PurchaseSupplier.apiRoute;
  }
  get detailPageRoute() {
    return PurchaseSupplier.detailPageRoute;
  }
  get listPageRoute() {
    return PurchaseSupplier.listPageRoute;
  }

  static get entityListDisplayAttrs() {
    return this.getAttributes(['number', 'supplier', 'totalAmount']);
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '0.75fr',
        },
      }),
      purchase: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_PURCHASE, {
        column: 'purchase_id',
        joinPath: ['purchase!fk_purchase_supplier_purchase_id_purchase'],
        tableProperties: {
          header: 'hit-base.common.purchase',
        },
      }),
      supplier: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_ADDRESS, {
        validations: {required},
        column: 'supplier_id',
        joinPath: ['address!fk_purchase_supplier_supplier_id_address'],
        fkToUpdate: 'supplier_id',
        tableProperties: {
          header: 'hit-app.common.supplier',
        },
      }),
      totalAmount: new HitContainerAttribute(ATTRIBUTE_TYPES.NOT_FETCHED, {
        readOnly: true,
        tableProperties: {
          header: 'hit-app.common.amount',
          maxWidth: '0.75fr',
          readOnly: true,
        },
        forcedType: 'decimal',
      }),
    };
  }
}
