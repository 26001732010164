import {HitUtils} from './HitUtils';

export class HitHtmlUtils extends HitUtils {
  /**
   * When a rich text input once contained a value and this value is deleted,
   * in the database <p></p> is stored
   * This function reads the html value and checks if it has a real value to display
   */
  static htmlHasContent(value) {
    if (!value) return false;
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = value;
    tempDiv.querySelectorAll(':empty').forEach((el) => el.remove());
    const meaningfulText = tempDiv.innerText.trim();
    return meaningfulText.length > 0;
  }
}
