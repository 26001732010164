import BaseModel from './BaseModel';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';
import {HitOnlineEnums} from 'hit-online-web-ui/src/helpers/constants';
import {translate} from '../../plugins/i18n/i18n';

export default class Journal extends BaseModel {
  constructor({
    id,
    number,
    designation,
    active,
    journalType,
    transactionType,
    periodicNumbering,
    periodicNumberingLength,
    nextDocumentNumber,
  }) {
    super(id, number, designation);
    this.active = active;
    this.journalType = journalType
      ? HitOnlineEnums.getEnumValue('JOURNAL_TYPE', journalType, translate)
      : null;
    this.transactionType = transactionType
      ? HitOnlineEnums.getEnumValue(
          'TRANSACTION_TYPE',
          transactionType,
          translate
        )
      : null;
    this.periodicNumbering = periodicNumbering;
    this.periodicNumberingLength = periodicNumberingLength;
    this.nextDocumentNumber = nextDocumentNumber;
  }

  static get config() {
    const config = BaseModel.config;
    config['importable'] = false;
    return config;
  }
  get config() {
    return Journal.config;
  }

  static get fkName() {
    return 'journal_id';
  }
  static get apiRoute() {
    return 'journal';
  }
  static get detailPageRoute() {
    return 'journalDetail';
  }
  static get listPageRoute() {
    return 'journalList';
  }
  get fkName() {
    return Journal.fkName;
  }
  get apiRoute() {
    return Journal.apiRoute;
  }
  get listPageRoute() {
    return Journal.listPageRoute;
  }
  get detailPageRoute() {
    return Journal.detailPageRoute;
  }

  static get joinAttrsKey() {
    return ['number', 'designation', 'journalType', 'transactionType'];
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'periodicNumbering',
      'nextDocumentNumber',
      'periodicNumberingLength',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes(
      ['number', 'designation', 'journalType', 'transactionType'],
      []
    );
  }

  static get createInitColumns() {
    return {
      designation: '',
      periodic_numbering: false,
      active: true,
      next_document_no: 1,
    };
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '0.5fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-base.common.designation',
          maxWidth: '1fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'active',
          header: 'hit-app.common.active',
          maxWidth: '0.5fr',
        },
      }),
      journalType: new HitContainerAttribute(ATTRIBUTE_TYPES.ENUMERATION, {
        enumType: 'JOURNAL_TYPE',
        column: 'journal_type',
        tableProperties: {
          header: 'hit-app.journal.journal-type',
        },
      }),
      transactionType: new HitContainerAttribute(ATTRIBUTE_TYPES.ENUMERATION, {
        enumType: 'TRANSACTION_TYPE',
        column: 'transaction_type',
        tableProperties: {
          header: 'hit-app.transaction.transaction-type',
        },
      }),
      periodicNumbering: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'periodic_numbering',
        tableProperties: {
          header: 'hit-app.journal.periodic-numbering',
        },
      }),
      nextDocumentNumber: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'next_document_no',
        validations: {required},
        decimalPrecision: 0,
        decimalDefaultValue: 1,
        tableProperties: {
          header: 'hit-app.journal.next-document-number',
        },
      }),
      periodicNumberingLength: new HitContainerAttribute(
        ATTRIBUTE_TYPES.DECIMAL,
        {
          column: 'periodic_numbering_length',
          validations: {required},
          decimalPrecision: 0,
          decimalDefaultValue: 3,
          tableProperties: {
            header: 'hit-app.journal.numbering-length',
          },
        }
      ),
    };
  }
}
