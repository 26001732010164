import {BaseModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';

export default class Stock extends BaseModel {
  constructor({
    id,
    number,
    designation,
  }) {
    super(id, number, designation);
  }

  static get config () {
    const baseConfig = {...BaseModel.config};
    baseConfig["addable"] = false;
    return baseConfig;
  }

  static get fkName() {
    return 'stock_id';
  }
  static get apiRoute() {
    return 'stock';
  }
  static get detailPageRoute() {
    return 'stockList';
  }
  static get listPageRoute() {
    return 'stockList';
  }
  get fkName() {
    return Stock.fkName;
  }
  get apiRoute() {
    return Stock.apiRoute;
  }
  get listPageRoute() {
    return Stock.listPageRoute;
  }
  get detailPageRoute() {
    return Stock.detailPageRoute;
  }

  static get joinAttrsKey() {
    return ['number', 'designation'];
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes(
      ['number', 'designation']
    );
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '0.5fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-base.common.designation',
          maxWidth: '1fr',
        },
      }),
    };
  }
}