<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-resource')"
    route="resource"
    auto-complete-id="resource"
    :table-attributes="tableAttributes"
    :default-sort="{property: 'number', order: 'asc'}"
    :only-load-active-records="onlyLoadActiveRecords"
    :data-list-config="listConfig"
    @item-selected="selectItem"
  />
</template>

<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {useConfigurationStore} from '../../../store';
import {Resource} from '../../../models';

export default {
  name: 'HitResourceBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
    instantFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const c = useConfigurationStore();
    const {t, n} = useI18n();
    return {t, n, c};
  },
  data() {
    return {
      tableAttributes: Resource.browserDisplayAttrs,
      listConfig: {
        zone1Field: 'number',
        zone2Field: `designation${this.c.mainLanguagePascalCase}`,
        zone3CustomFunction: (item) =>
          `${this.t('hit-app.common.sales-price')}: ${this.n(
            parseFloat(item?.salesPrice ?? 0),
            'price'
          )} / ${item?.unit?.unit}`,
      },
    };
  },
  methods: {
    selectItem(data) {
      const res = new Resource(data);
      this.$emit('itemSelected', res);
    },
  },
  emits: ['itemSelected'],
};
</script>
