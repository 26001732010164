<template>
  <span
    :title="
      showTooltip
        ? formDefinition
          ? formatFormDefinition(formDefinition)
          : ''
        : ''
    "
  >{{ formDefinition ? formatFormDefinition(formDefinition) : '' }}</span>
</template>

<script>
import {HitLoadingEmitterMixin} from '@hit/components';

export default {
  inject: ['tHitField'],
  name: 'HitFormDefinition',
  components: {},
  mixins: [HitLoadingEmitterMixin],
  props: {
    /**
     * ID of the form definition to display
     */
    formDefinitionId: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Fields to display
     */
    fields: {
      type: Array,
      default: () => ['id'],
    },
    /**
     * Function to format the form definition
     */
    formatFn: {
      type: Function,
      required: false,
      default: null,
    },
    /**
     * Enable display of the tooltip
     */
    showTooltip: Boolean,
    /**
     * Hide loading of the form definition
     */
    hideLoading: Boolean,
  },
  data() {
    return {
      fetchFormDefinitionQueryId: null,
    };
  },
  computed: {
    queryFields() {
      if (this.fields) {
        return this.fields;
      } else {
        return ['id', this.tHitField('designation')];
      }
    },
    fetchFormDefinitionQueryParams() {
      return this.formDefinitionId
        ? {
            query: {id: this.formDefinitionId},
            fields: [this.queryFields],
          }
        : null;
    },
    formDefinition() {
      return this.formDefinitionId
        ? this.$store.getters['form/formDefinition/item'](this.formDefinitionId)
        : null;
    },
    isFormDefinitionLoading() {
      return (
        this.fetchFormDefinitionQueryId &&
        this.$store.getters['form/formDefinition/itemsLoading'](
          this.fetchFormDefinitionQueryId
        )
      );
    },
  },
  watch: {
    fetchFormDefinitionQueryParams() {
      this.fetchFormDefinition();
    },
    isFormDefinitionLoading: {
      handler(newValue) {
        if (this.hideLoading) {
          this.setLoading(newValue);
        }
      },
      immediate: true,
    },
  },
  mounted() {
    this.fetchFormDefinition();
  },
  methods: {
    formatFormDefinition(formDefinition) {
      if (this.formatFn) {
        return this.formatFn(formDefinition);
      } else {
        return `${formDefinition[this.tHitField('designation')]}`;
      }
    },
    async fetchFormDefinition() {
      this.fetchFormDefinitionQueryId = null;
      if (this.fetchFormDefinitionQueryParams) {
        this.fetchFormDefinitionQueryId = await this.$store.dispatch(
          'form/formDefinition/fetchItem',
          this.fetchFormDefinitionQueryParams
        );
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
