import {parseLocale} from '../../utils';

export default class Language {
  constructor(locale) {
    const {id, idApp, shortDesignation, longDesignation} = parseLocale(locale);
    this.id = id;
    this.idApp = idApp;
    this.shortDesignation = shortDesignation;
    this.longDesignation = longDesignation;
  }

  /**
   * We need to define this getter because this prop is used in the fast mode browser
   */
  get fullDesignation() {
    return this.longDesignation;
  }
}
