<template>
  <hit-form
    :value="value"
    :autosave="false"
    :cancellable="true"
    :validations="validations"
    @save="onSave"
    @cancel="onCancel"
  >
    <template #default="{formData, validationState, changeField}">
      <hit-input
        type="text"
        :value="formData.id"
        name="id"
        :label="t('hit-base.form.input-id')"
        :validation-state="validationState.id"
      >
        <template #help>
          <hit-help
            :text="t('hit-base.form.input-id-help')"
            :label="t('hit-base.form.input-id')"
          />
        </template>
      </hit-input>

      <hit-app-input-multi-languages
        type="text"
        :value="formData"
        field="label"
        instant-touch
        instant-focus
        all-languages-visible-default
        :label="t('hit-base.common.designation')"
        :validation-state="validationState"
      />

      <hit-app-input-multi-languages
        type="richtext"
        :value="formData"
        field="help"
        :label="t('hit-base.form.help')"
        :validation-state="validationState"
      />

      <slot
        :form-data="formData"
        :validation-state="validationState"
        :change-field="changeField"
      />

      <hit-form-definition-builder-constraints-component
        v-if="formData.constraints"
        :value="formData.constraints"
        :validation-state="validationState.constraints"
        :component-type="formData.componentType"
        name="constraints"
      />
    </template>
  </hit-form>
</template>

<script>
import HitFormDefinitionBuilderConstraintsComponent from './HitFormDefinitionBuilderConstraintsComponent';
import {useI18n} from 'vue-i18n';
import {HitAppInputMultiLanguages} from '../../input';
import {HitForm, HitHelp, HitInput} from '@hit/components';

export default {
  name: 'HitFormDefinitionBuilderBaseComponent',
  components: {
    HitForm,
    HitInput,
    HitHelp,
    HitFormDefinitionBuilderConstraintsComponent,
    HitAppInputMultiLanguages,
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  props: {
    /**
     * Component of the builder to edit
     */
    value: {
      type: Object,
      required: true,
    },
    /**
     * Validations for the component
     */
    validations: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onSave($event) {
      /**
       * When the component is saved
       */
      this.$emit('save', $event);
      if ($event.stopImmediatePropagation) {
        $event.stopImmediatePropagation();
      }
    },
    onCancel($event) {
      /**
       * When the changes made to the component are cancelled
       */
      this.$emit('cancel', $event);
      if ($event) {
        if ($event.stopImmediatePropagation) {
          $event.stopImmediatePropagation();
        }
      }
    },
  },

  /**
   * Defines all the events that are emitted in this component to stop the propagation
   * of the event listeners to the child components (HitForm in this case)
   */
  emits: ['save', 'cancel'],
};
</script>

<style scoped></style>
