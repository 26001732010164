<template>
  <hit-input
    :value="timeValue"
    @change="onTimeChange"
  />
</template>
<script>
import HitInput from './HitInput.vue';
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitInputHours',
  components: {
    HitInput,
  },

  setup() {
    const {t} = useI18n();
    return {t};
  },

  props: {
    value: {
      type: Number,
      required: false,
      default: 0,
    },
    steps: {
      type: Number,
      required: false,
      default: 0,
    },

    /**
     * When we want to display an error message in case of a wrong input syntax
     */
    notificationStore: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    timeValue() {
      const hours = Math.floor(this.value / 60 / 60);
      const minutes = (this.value / 60) % 60;
      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      let result = `${formattedHours}:${formattedMinutes}`;
      return result;
    },
  },
  methods: {
    onTimeChange(timeValue) {
      if (!this.syntaxIsCorrect(timeValue)) {
        if (this.notificationStore) {
          this.notificationStore.insertBannerNotification(
            this.t('hit-components.common.wrong-hours-syntax')
          );
        }
        return;
      }
      if (/[^0-9:]/.test(timeValue)) {
        this.$emit('changeTime', 0);
        return;
      }
      const hours = parseInt(timeValue.split(':')[0]);
      let minutes = 0;
      if (timeValue.split(':')[1]) {
        minutes = parseInt(timeValue.split(':')[1]);
        if (minutes > 59) {
          this.$emit('changeTime', 0);
          return;
        }
      }
      let resultMinutes = hours * 60 + minutes;
      if (this.steps > 0) {
        resultMinutes -= resultMinutes % this.steps;
      }
      this.$emit('changeTime', resultMinutes * 60);
    },

    /**
     * Checks if the passed string respects the following syntax: xx:yy
     * - xx can be an integer of all value 0 -> infinity
     * - yy must be an integer between 0 and 59
     */
    syntaxIsCorrect(hoursString) {
      const durationPattern = /^(\d+):(\d{1,2})$/;
      const match = hoursString.match(durationPattern);
      if (!match) return false;
      const minutes = parseInt(match[2], 10);
      return !(minutes < 0 || minutes > 59);
    },
  },
};
</script>
