<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-form-def')"
    route="form_definition"
    :table-attributes="tableAttributes"
    auto-complete-id="form-def"
    :default-sort="defaultSort"
    :data-list-config="dataListConfig"
    @item-selected="selectItem"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {FormDefinitionNew, useConfigurationStore} from '@hit/base';

export default {
  inject: ['tHitField'],
  name: 'HitFormDefBrowser',
  components: {
    HitEntityBrowser,
  },
  setup() {
    const {t} = useI18n();
    const c = useConfigurationStore();
    return {t, c};
  },
  data() {
    return {
      tableAttributes: FormDefinitionNew.browserDisplayAttrs,
      defaultSort: {
        property: `designation${this.c.mainLanguagePascalCase}`,
        order: 'asc',
      },
      dataListConfig: {
        zone2Field: `designation${this.c.mainLanguagePascalCase}`,
      },
    };
  },
  methods: {
    selectItem(data) {
      const formDef = new FormDefinitionNew(data);
      this.$emit('itemSelected', formDef);
    },
  },
  emits: ['itemSelected'],
};
</script>
