<template>
  <hit-select
    :label="label"
    :value="value"
    :placeholder="
      placeholder === undefined
        ? t('hit-components.common.please-select-value')
        : placeholder
    "
    :multiple="multiple"
    :label-fields="labelFields"
    :filterable="filterable"
    :clearable="clearable"
    :create-label="
      createLabel === undefined
        ? t('hit-components.common.create-item')
        : createLabel
    "
    :loading="loading"
    :options="options"
    :selected-options="selectedOptions"
    :dropdown-label-fields="dropdownLabelFields"
    :label-separator="labelSeparator"
    :custom-filter="customFilter"
    :adapt-to-content="adaptToContent"
    :options-loading="optionsLoading"
    :custom-actions="customActions"
    :placeholder-visible="placeholderVisible"
    :borders-visible="bordersVisible"
    :get-label-from-item="getLabelFromItem"
    :readonly="readonly"
    :disabled="disabled"
    :validation-state="validationState"
    :enable-clear="!!clearable"
    :inline-input="inlineInput"
    :reset-validation-if-valid="resetValidationIfValid"
    :instant-focus="instantFocus"
    @change="onChange"
    @filter="onFilter"
    @show-options="onShowOptions"
  >
    <template #itemDesignation="{item}">
      <!-- @slot Designation of the item -->
      <slot
        name="itemDesignation"
        :item="item"
      />
    </template>
    <template #help>
      <!-- @slot Help of the select -->
      <slot name="help" />
    </template>
    <template #creation="{closeSlotFn, selectFn, closeDropdownFn}">
      <slot
        name="creation"
        :close-slot-fn="closeSlotFn"
        :select-fn="selectFn"
        :close-dropdown-fn="closeDropdownFn"
      />
    </template>
    <template #default="{formData, validationState: creationFormValidationState}">
      <!-- @slot Item creation form inputs -->
      <slot
        name="createForm"
        :form-data="formData"
        :validation-state="creationFormValidationState"
      />
    </template>
  </hit-select>
</template>

<script>
import {HitFormValidationMixin, HitSelectMixin} from '@hit/components';
import {useI18n} from 'vue-i18n';

export default {
  inject: ['tHitValue'],
  name: 'HitAppSelect',
  components: {},
  mixins: [HitSelectMixin, HitFormValidationMixin],
  props: {
    instantFocus: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n(); // use as global scope
    return {t};
  },
  methods: {
    getLabelFromItem(item) {
      if (item.label) {
        return this.tHitValue(item.label);
      } else {
        return this.tHitValue(item);
      }
    },
    onChange($event) {
      this.$emit('change', $event);
    },
    onFilter($event) {
      this.$emit('filter', $event);
    },
    onShowOptions($event) {
      this.$emit('show-options', $event);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-buttons {
  grid-template-rows: max-content 1fr max-content;
  grid-template-areas: 'cancel-button . create-button';
}

.cancel-button {
  grid-area: cancel-button;
}

.create-button {
  grid-area: create-button;
}
</style>

<style lang="scss">
.app-select-form {
  .hit-form-row {
    grid-template-columns: 1fr 1fr;
  }

  input {
    @apply w-full;
  }
}
</style>
