import {defineStore} from 'pinia';
import {globals} from 'hit-online-web-ui/src/globals';
import I18nKey from '../../models/i18n/I18nKey';
import uuid from '@hit/components/src/utils/uuid/uuid';
import {
  DataService,
  ActionService,
  NotificationService,
  useActionStore,
} from '@hit/base';
import {translate} from '@hit/base/src/plugins/i18n/i18n';

export class HitBannerNotificationItem {
  static WARNING = 'warning';
  static INFO = 'info';
  static SUCCESS = 'success';
  static DANGER = 'danger';

  constructor(id, message, level, details) {
    this.id = id;
    this.level = level;
    this.count = 1;
    if (message instanceof I18nKey) {
      this.message = message.key;
      this.messageNoItems = message.noItems;
    } else {
      this.message = message;
      this.messageNoItems = null;
    }
    this.details = details;
  }
}

export const useNotificationsStore = defineStore('notifications', {
  state: () => ({
    notificationsMap: new Map(),
    bannersMap: new Map(),
    actionFlag: false, //used to refresh actionList
    gitUuidRegex: /0000-4000-8000-000000000000$/,
  }),
  getters: {
    notifications: (state) => Array.from(state.notificationsMap.values()),
    banners: (state) => Array.from(state.bannersMap.values()),
    unreadNotifications: (state) => {
      return state.notifications.filter((notif) => !notif.read);
    },
    forceShow: (state) => {
      return state.notifications.some((n) => n.show === true);
    },
  },
  actions: {
    /**
     * Inserts the notification into the notification store
     * @param notification
     */
    async insertNotification(notification) {
      // This notification type is used to execute special code on client side
      if (notification.type.toUpperCase() === 'SYSTEM') {
        switch (notification.body) {
          case 'reload':
            this.insertBannerNotification(
              translate('hit-base.banners.page-reload'),
              HitBannerNotificationItem.WARNING
            );
            setTimeout(async () => {
              NotificationService.deleteSystemNotification(
                notification.notification_id
              ).then(() => {
                location.reload(true);
              });
            }, 10000);
            break;
          case 'reload-assigned-actions':
            await useActionStore().updateAssignedActions();
            break;
          default:
            throw Error('Unsupported system notification');
        }
      }
      // This notification type is used to handle the actions
      else if (notification.type.toUpperCase() === 'ACTION') {
        this.actionFlag = false;
        try {
          notification.body = JSON.parse(notification.body);
        } catch (error) {
          // If JSON parsing fails, assume the body is a simple string and continue
        }
        this.handleActionNotification(notification);
      } else {
        this.actionFlag = false;
        /*eslint no-empty: "error"*/
        try {
          notification.body = JSON.parse(notification.body);
        } catch {
          // When this blocks throws an error, the body is a simple string. We continue without parsing it
        }
        if (!('read' in notification)) {
          notification['read'] = false;
        }
        this.actionFlag = true;
        this.notificationsMap.set(notification.notification_id, notification);
        if (notification.ttl > 0) {
          setTimeout(
            () => this.deleteNotification(notification.notification_id),
            notification.ttl * 1000
          );
        }
      }
    },

    integerToUuid(seed) {
      if (seed < 0 || seed > 0xffffffff) {
        throw new Error('Seed must be a 32-bit integer');
      }
      let seedHex = seed.toString(16).padStart(8, '0');
      return `${seedHex.substring(0, 4)}${seedHex.substring(
        4
      )}-0000-4000-8000-000000000000`;
    },

    uuidToInteger(uuidStr) {
      let seedHex = uuidStr.substring(0, 8);
      return parseInt(seedHex, 16);
    },

    async handleActionNotification(notification) {
      const userResponse = await DataService.read('staff', {
        attributes: 'id,user_id',
        filters: {user_id: 'eq.' + notification.body.user_info.user_id},
      });

      if (
        !userResponse ||
        !userResponse.data ||
        userResponse.data.length === 0
      ) {
        console.error('No staff ID found for user.');
        return;
      }

      const userStaffId = userResponse.data[0].id;
      const userId = userResponse.data[0].user_id;
      const action_id = this.integerToUuid(
        notification.body.issue_attributes.id
      );

      // Check if action exists
      const actionResponse = await DataService.read('action', {
        attributes: 'id',
        filters: {id: 'eq.' + action_id},
      });

      let status = 'OPEN';
      let assigneeStaffId = '';

      if (notification.body.assignee_info !== null) {
        const assigneeResponse = await DataService.read('staff', {
          attributes: 'id',
          filters: {
            user_id: 'eq.' + notification.body.assignee_info.assignee_id,
          },
        });

        if (
          assigneeResponse &&
          assigneeResponse.data &&
          assigneeResponse.data.length > 0
        ) {
          status = 'ASSIGNED';
          assigneeStaffId = assigneeResponse.data[0].id;
        }
      }

      if (
        actionResponse &&
        actionResponse.data &&
        actionResponse.data.length > 0
      ) {
        await DataService.update(
          'action',
          {id: 'eq.' + action_id},
          {
            status: status,
            due_date: notification.body.issue_attributes.due_date,
            designation: notification.body.issue_attributes.title,
            description: notification.body.issue_attributes.description,
            modification_date: new Date(),
            modification_user_id: this.uuid,
            assignee_id: assigneeStaffId || null,
          }
        );
      } else {
        await DataService.create('action', {
          id: action_id,
          no: notification.body.issue_attributes.number,
          author_id: userStaffId,
          assignee_id: assigneeStaffId || null,
          url: 'http://gitlab.ficos.com/hit-online/',
          creation_user_id: notification.body.user_info.user_id,
          due_date: notification.body.issue_attributes.due_date,
          designation: notification.body.issue_attributes.title,
          description: notification.body.issue_attributes.description,
          status: status,
        });
      }

      if (assigneeStaffId.length > 0 && status === 'ASSIGNED') {
        await DataService.create('action_history', {
          id: crypto.randomUUID(),
          action_id: action_id,
          operation: 'ASSIGN',
          assignee_id: assigneeStaffId,
          creation_user_id: userId,
        });
      }
      this.actionFlag = true;
      // this.notificationsMap.set(notification.notification_id, notification);   No Need to show this notification, if assigned will show with above code
    },

    async updateGitLabIssue(
      newAssigneeId,
      newTitle,
      newDescription,
      newDueDate,
      newStatus,
      card
    ) {
      if (card.url === 'http://gitlab.ficos.com/hit-online/') {
        let newAssigneeUser = null;
        if (newAssigneeId !== '') {
          const userResponse = await DataService.read('staff', {
            attributes: 'user_id',
            filters: {id: 'eq.' + newAssigneeId},
          });
          newAssigneeUser = userResponse.data[0].user_id;
        }

        const issueData = {
          newAssigneeUser,
          newTitle,
          newDescription,
          newDueDate,
          newStatus,
          card,
        };
        ActionService.post('gitlab/issues', {
          values: issueData,
        }).catch((error) => {
          this.showPrintDialog = false;
        });
      }
    },

    /**
     * Updates all the read flags to true
     */
    setAllNotificationsAsRead() {
      Array.from(this.notificationsMap.values()).forEach((notification) => {
        const notificationCopy = {...notification};
        notificationCopy.read = true;
        notificationCopy.show = false;
        this.notificationsMap.set(
          notificationCopy.notification_id,
          notificationCopy
        );
      });
      DataService.update(
        'notification',
        {user_id: `eq.${globals.$keycloak.idTokenParsed.sub}`},
        {
          read: true,
          show: false,
        }
      ).then((r) => {});
    },

    /**
     * Deletes the notification locally and removes it from the database
     * @param id
     */
    deleteNotification(id) {
      const notification = this.notifications.filter(
        (notification) => notification.notification_id === id
      );
      if (notification.length > 0) {
        DataService.delete('notification', {
          notification_id: `eq.${notification[0].notification_id}`,
          user_id: `eq.${globals.$keycloak.idTokenParsed.sub}`,
        }).then((r) => {
          this.notificationsMap.delete(id);
        });
      }
    },

    /**
     * Actions used for the banner notifications that are directly displayed on top of the application
     */
    insertBannerNotification(
      message,
      level = HitBannerNotificationItem.DANGER,
      details = null
    ) {
      const notificationAlreadyPresent = Array.from(
        this.bannersMap.values()
      ).some((obj) => obj.message === message);
      if (!notificationAlreadyPresent) {
        const id = uuid.generate();
        this.bannersMap.set(
          id,
          new HitBannerNotificationItem(id, message, level, details)
        );
      } else {
        const existingBanner = Array.from(this.bannersMap.values()).find(
          (obj) => obj.message === message
        );
        existingBanner.count++;
      }
    },

    /**
     * Removes a banner notification from the store
     * @param id
     */
    deleteBannerNotification(id) {
      this.bannersMap.delete(id);
    },
  },
});
