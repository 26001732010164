<template>
  <hit-base-input
    :label="label"
    :validation-state="validationState"
    use-custom-height
    :full-width="false"
  >
    <div class="grid grid-cols-1">
      <div
        v-for="(item, itemId) in options"
        :key="getItemId(item, itemId)"
        class="relative flex items-start py-1"
      >
        <div class="flex items-center h-5">
          <input
            :id="getItemId(item, itemId)"
            :checked="getItemId(item, itemId) === idValue"
            type="radio"
            class="h-4 w-4 text-checkbox-checked border-input focus:ring-transparent transition duration-150 ease-in-out"
            :disabled="disabled || readonly"
            style="color: var(--color-accent)"
            @change="onInputRadioChange"
          >
        </div>
        <hit-input-option
          :id="getItemId(item, itemId)"
          :label="getLocalizedValue(item.label)"
          :help="getLocalizedValue(item.help)"
        />
      </div>
    </div>
    <template #help>
      <!-- @slot Help of the input -->
      <slot name="help" />
    </template>
  </hit-base-input>
</template>

<script>
import {HitFormValidationMixin, HitInputMixin} from '../../../mixins';
import HitInputOption from './HitInputOption';
import HitBaseInput from '../input/HitBaseInput.vue';

export default {
  name: 'HitInputRadioButton',
  inheritAttrs: false,
  components: {HitInputOption, HitBaseInput},
  mixins: [HitInputMixin, HitFormValidationMixin],
  props: {
    /**
     * Options of the input
     */
    options: {
      type: [Array, Object],
      required: true,
    },
    /**
     * Value of the input
     */
    value: {
      type: [Boolean, String],
      required: false,
      default: null,
    },
    /**
     * Function to get the localized value of the label and help
     */
    getLocalizedValue: {
      type: Function,
      default: (item) => {
        return item;
      },
    },
  },
  computed: {
    idValue() {
      if (typeof this.value === 'boolean' || typeof this.value === 'number') {
        for (const [key, item] of Object.entries(this.options)) {
          if (item.dbValue === this.value) {
            return this.getItemId(item, key);
          }
        }
        return this.value;
      } else {
        return this.value;
      }
      //return this.options[0].id;
    },
  },
  methods: {
    onInputRadioChange($event) {
      this.fireInputChange(this.dbValueFromId($event.target.id));
      if (this.validationState) {
        this.validationState.$touch();
      }
    },
    dbValueFromId(id) {
      if (this.options[id] && 'dbValue' in this.options[id]) {
        return this.options[id].dbValue;
      } else {
        return id;
      }
    },
  },
};
</script>
