<template>
  <hit-form-definition-builder-base-component
    :validations="{...validations, ...stepsValidations}"
    :value="value"
  >
    <template #default="{formData, validationState, changeField}">
      <hit-input
        type="number"
        :label="t('hit-base.form.steps-in-minutes')"
        :value="formData.steps"
        :validation-state="validationState.steps"
        @change="(steps) => changeField('steps', steps)"
      />
    </template>
  </hit-form-definition-builder-base-component>
</template>

<script>
import HitFormDefinitionBuilderBaseComponent from './HitFormDefinitionBuilderBaseComponent';
import {useI18n} from 'vue-i18n';
import {HitInput} from '@hit/components';
import {integer, minValue, required} from '@vuelidate/validators';

export default {
  name: 'HitFormDefinitionBuilderHoursComponent',
  components: {
    HitFormDefinitionBuilderBaseComponent,
    HitInput,
  },
  props: {
    /**
     * Entity component of the builder to edit
     */
    value: {
      type: Object,
      required: true,
    },
    /**
     * Validations for the entity component
     */
    validations: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stepsValidations: {
        steps: {
          required: required,
          integer: integer,
          minValue: minValue(0),
        },
      },
    };
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },

  /**
   * The save and cancel event is emitted inside the HitFormDefinitionBuilderBaseComponent
   * We do not catch it here and do not define the events in this component. Like this,
   * the event listeners added to this component propagates to the base builder
   */
  emits: [],
};
</script>
