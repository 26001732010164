<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-project')"
    route="project"
    :table-attributes="tableAttributes"
    auto-complete-id="project"
    :default-sort="{property: 'no', order: 'asc'}"
    :only-load-active-records="onlyLoadActiveRecords"
    :data-list-config="dataListConfig"
    @item-selected="selectItem"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {Project} from '../../../models';
import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';
export default {
  name: 'HitProjectBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tableAttributes: Project.browserDisplayAttrs,
      dataListConfig: {
        zone3CustomFunction: (item) => HitUtils.fullLocationString(item),
      },
    };
  },
  methods: {
    selectItem(data) {
      const project = new Project(data);
      this.$emit('item-selected', project);
    },
  },
  emits: ['itemSelected'],
};
</script>
