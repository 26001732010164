import {MultiLanguageDesignationModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';
import BaseModel from './BaseModel';

const has2Letters = (data) => {
  return data.length === 2;
};

export default class Country extends MultiLanguageDesignationModel {
  constructor({
    id,
    number,
    designationFrBe = undefined,
    designationDeBe = undefined,
    designationNlBe = undefined,
    designationFrLu = undefined,
    designationDeLu = undefined,
    designationFrFr = undefined,
    designationDeDe = undefined,
    designationNlNl = undefined,
    designationEnGb = undefined,
    designationEnIn = undefined,
    designationMrIn = undefined,
    designationHiIn = undefined,
    active = true,

    // We also need to add the key how it is stored in the db (we use the db column names)
    no = undefined,
    designation = undefined,
  }) {
    super({
      id,
      number: number || no,
      designationFrBe,
      designationDeBe,
      designationNlBe,
      designationFrLu,
      designationDeLu,
      designationFrFr,
      designationDeDe,
      designationNlNl,
      designationEnGb,
      designationEnIn,
      designationMrIn,
      designationHiIn,
    });
    this.active = active;

    // For form items where we only store the designation in the data
    if (designation) {
      this.designation = designation;
    }
  }

  static get config() {
    const config = BaseModel.config;
    config['addable'] = false;
    return config;
  }
  get config() {
    return Country.config;
  }

  static get fkName() {
    return 'country';
  }
  static get apiRoute() {
    return 'country';
  }
  static get detailPageRoute() {
    return 'commonCountryList';
  }
  static get listPageRoute() {
    return 'commonCountryList';
  }
  get fkName() {
    return Country.fkName;
  }
  get apiRoute() {
    return Country.apiRoute;
  }
  get listPageRoute() {
    return Country.listPageRoute;
  }
  get detailPageRoute() {
    return Country.detailPageRoute;
  }
  static get attrsToStoreInForm() {
    return ['number', this.attributeUserLocale('designation')];
  }
  get attrsToStoreInForm() {
    return BaseModel.attrsToStoreInForm;
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
    ]);
  }

  static get joinAttrsKey() {
    return [
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
    ];
  }

  static get allAttributes() {
    return {
      id: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-base.common.id',
          maxWidth: '1fr',
        },
      }),
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        validations: {required, has2Letters},
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      ...this.generateMultiLanguageField({
        key: 'designation',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.designation',
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'active',
        creationValue: true,
        tableProperties: {
          header: 'hit-app.common.active',
          width: '0.25fr',
        },
      }),
    };
  }
}
