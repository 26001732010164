<template>
  <hit-base-input
    :label="label"
    :validation-state="validationState"
    use-custom-height
    :full-width="false"
  >
    <div
      ref="hit-code-editor"
      class="hit-code-editor"
      :class="{
        'border-input':
          ((valid && pristine) || !validationState || (valid && dirty)) &&
          !focussed,
        'border-input-focus':
          ((valid && pristine) || !validationState) && focussed,
        'border-danger': invalid && dirty,
      }"
    />
    <template #help>
      <slot name="help" />
    </template>
  </hit-base-input>
</template>

<script>
import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.js';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/yaml/yaml.js';
import 'codemirror/mode/javascript/javascript.js';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/fold/foldgutter.js';
import 'codemirror/addon/fold/indent-fold';
import 'codemirror/addon/lint/lint.js';
import 'codemirror/addon/lint/lint.css';
import 'codemirror/addon/lint/yaml-lint';
import HitBaseInput from './HitBaseInput';
import HitInputMixin from '../../../mixins/form/HitInputMixin';
import HitFormValidationMixin from '../../../mixins/form/HitFormValidationMixin';

export default {
  name: 'HitInputCode',
  components: {HitBaseInput},
  mixins: [HitInputMixin, HitFormValidationMixin],
  props: {
    /**
     * Value of the code input
     */
    value: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Programming language of the code input
     */
    language: {
      type: String,
      default: 'yaml',
      validator(value) {
        return ['yaml'].includes(value);
      },
    },
  },
  data() {
    return {
      focussed: false,
      hasContentBeenChanged: false,
      hasEditorContentLoaded: false,
    };
  },
  computed: {
    editorValue() {
      if (this.value) {
        return this.value;
      }
      return '';
    },
  },
  watch: {
    value: {
      handler(newValue) {
        if (this.editor) {
          // Need to retain current position of cursor because CodeMirror re-renders on value update
          const cursorPosition = this.editor.getCursor();
          this.editor.setValue(newValue);
          this.editor.setCursor(cursorPosition);
        }
      },
    },
  },
  mounted() {
    this._initialize();
  },
  methods: {
    _initialize() {
      let editorMode = null;

      if (this.language === 'yaml') {
        editorMode = 'text/x-yaml';
      }

      const editor = CodeMirror(this.$refs['hit-code-editor'], {
        lineNumbers: true,
        tabSize: 2,
        mode: editorMode,
        lint: true,
        foldGutter: true,
        gutters: [
          'error',
          'CodeMirror-linenumbers',
          'CodeMirror-lint-markers',
          'CodeMirror-foldgutter',
        ],
      });
      editor.setValue(this.editorValue);

      editor.on('change', (coder) => {
        // When setting value first time, onChange is triggered.
        // This code avoids to save after blur after setting the content
        if (!this.hasEditorContentLoaded) {
          this.hasEditorContentLoaded = true;
        } else {
          const editorValue = coder.getValue();
          this.fireInputInput(editorValue);
          this.hasContentBeenChanged = true;
          if (this.validationState) {
            this.validationState.$touch();
          }
        }
      });

      editor.on('focus', () => {
        this.focussed = true;
      });

      editor.on('blur', (coder) => {
        if (this.focussed) {
          const editorValue = coder.getValue();
          if (this.hasContentBeenChanged) {
            this.fireInputChange(editorValue);
            this.hasContentBeenChanged = false;
          }
          this.focussed = false;
        }
      });
    },
    createMarker(message) {
      const marker = document.createElement('div');
      marker.classList.add('error-marker');
      marker.innerHTML = '&nbsp;';

      const error = document.createElement('div');
      error.innerHTML = message;
      error.classList.add('error-message');
      marker.appendChild(error);

      return marker;
    },
  },
};
</script>

<style lang="scss">
.hit-code-editor {
  @apply border rounded;

  .CodeMirror {
    @apply rounded;
  }

  .error-marker {
    color: black;
    width: 10px !important;
    background-color: #ff0000;
  }

  .error-marker .error-message {
    display: none;
    position: absolute;
    background-color: #ddd;
    border: 1px solid #999;
    padding: 6px;
    width: 140px;
    left: 15px;
    top: -1em;
  }

  .error-marker:hover .error-message {
    display: block;
  }

  .CodeMirror-sizer {
    max-width: 0 !important;
    min-width: 0 !important;
  }
}

/* Style to adapt font family */
/*.CodeMirror-code {
  font-family: Open Sans, sans-serif;
}*/
</style>
