import BaseModel from './BaseModel';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required, url} from '@vuelidate/validators';
import {Country, DueDate, Journal, Language} from './index';
import TaxCode from './TaxCode';
import {DataService} from '../../api';
import {useNotificationsStore} from '../../store';
import {translate} from '../../plugins/i18n/i18n';

export default class Address extends BaseModel {
  constructor({
    id,
    number,
    designation,
    active = true,
    customer = false,
    supplier = false,
    potentialCustomer = false,
    potentialSupplier = false,
    street = undefined,
    houseNo = undefined,
    boxNo = undefined,
    postcode = undefined,
    city = undefined,
    country = undefined,
    memo = undefined,
    salesInvoiceJournal = undefined,
    salesInvoiceTaxCode = undefined,
    salesInvoiceDueDate = undefined,
    salesCreditNoteJournal = undefined,
    salesCreditNoteTaxCode = undefined,
    salesCreditNoteDueDate = undefined,
    purchaseInvoiceJournal = undefined,
    purchaseInvoiceTaxCode = undefined,
    purchaseInvoiceDueDate = undefined,
    purchaseCreditNoteJournal = undefined,
    purchaseCreditNoteTaxCode = undefined,
    purchaseCreditNoteDueDate = undefined,

    // We also need to add the key how it is stored in the db (we use the db column names)
    no = undefined,
  }) {
    super(id, number || no, designation);
    this.active = active;
    this.customer = customer;
    this.supplier = supplier;
    this.potentialCustomer = potentialCustomer;
    this.potentialSupplier = potentialSupplier;
    this.street = street;
    this.houseNo = houseNo;
    this.boxNo = boxNo;
    this.postcode = postcode;
    this.city = city;
    this.country = country ? new Country(country) : null;
    this.memo = memo;
    this.salesInvoiceJournal = salesInvoiceJournal
      ? new Journal(salesInvoiceJournal)
      : null;
    this.salesInvoiceTaxCode = salesInvoiceTaxCode
      ? new TaxCode(salesInvoiceTaxCode)
      : null;
    this.salesInvoiceDueDate = salesInvoiceDueDate
      ? new DueDate(salesInvoiceDueDate)
      : null;
    this.salesCreditNoteJournal = salesCreditNoteJournal
      ? new Journal(salesCreditNoteJournal)
      : null;
    this.salesCreditNoteTaxCode = salesCreditNoteTaxCode
      ? new TaxCode(salesCreditNoteTaxCode)
      : null;
    this.salesCreditNoteDueDate = salesCreditNoteDueDate
      ? new DueDate(salesCreditNoteDueDate)
      : null;
    this.purchaseInvoiceJournal = purchaseInvoiceJournal
      ? new Journal(purchaseInvoiceJournal)
      : null;
    this.purchaseInvoiceTaxCode = purchaseInvoiceTaxCode
      ? new TaxCode(purchaseInvoiceTaxCode)
      : null;
    this.purchaseInvoiceDueDate = purchaseInvoiceDueDate
      ? new DueDate(purchaseInvoiceDueDate)
      : null;
    this.purchaseCreditNoteJournal = purchaseCreditNoteJournal
      ? new Journal(purchaseCreditNoteJournal)
      : null;
    this.purchaseCreditNoteTaxCode = purchaseCreditNoteTaxCode
      ? new TaxCode(purchaseCreditNoteTaxCode)
      : null;
    this.purchaseCreditNoteDueDate = purchaseCreditNoteDueDate
      ? new DueDate(purchaseCreditNoteDueDate)
      : null;
  }

  static get fkName() {
    return 'address_id';
  }
  static get apiRoute() {
    return 'address';
  }
  static get listPageRoute() {
    return 'addressList';
  }
  static get detailPageRoute() {
    return 'addressDetail';
  }
  get fkName() {
    return Address.fkName;
  }
  get apiRoute() {
    return Address.apiRoute;
  }
  get listPageRoute() {
    return Address.listPageRoute;
  }
  get detailPageRoute() {
    return Address.detailPageRoute;
  }

  get salesInvoicePrefComplete() {
    return this.salesInvoiceJournal && this.salesInvoiceDueDate;
  }
  get salesCreditNotePrefComplete() {
    return this.salesCreditNoteJournal && this.salesCreditNoteDueDate;
  }
  get purchaseInvoicePrefComplete() {
    return this.purchaseInvoiceJournal && this.purchaseInvoiceDueDate;
  }
  get purchaseCreditNotePrefComplete() {
    return this.purchaseCreditNoteJournal && this.purchaseCreditNoteDueDate;
  }

  get newTransactionPreferenceValues() {
    let journal = null;
    let dueDateConfig = null;
    if (this.salesInvoicePrefComplete) {
      journal = this.salesInvoiceJournal;
      dueDateConfig = this.salesInvoiceDueDate;
    } else if (this.purchaseInvoicePrefComplete) {
      journal = this.purchaseInvoiceJournal;
      dueDateConfig = this.purchaseInvoiceDueDate;
    } else if (this.salesCreditNotePrefComplete) {
      journal = this.salesCreditNoteJournal;
      dueDateConfig = this.salesCreditNoteDueDate;
    } else if (this.purchaseCreditNotePrefComplete) {
      journal = this.salesCreditNoteJournal;
      dueDateConfig = this.salesCreditNoteDueDate;
    }
    let dueDate = new Date();
    if (!journal)
      return {
        due_date: dueDate,
        address_id: this.id,
      };
    if (dueDateConfig?.endOfMonth) {
      dueDate.setMonth(dueDate.getMonth() + 1);
      dueDate.setDate(0);
    }
    dueDate.setDate(dueDate.getDate() + dueDateConfig?.days ?? 0);
    return {
      journal_id: journal.id,
      due_date: dueDate,
      address_id: this.id,
    };
  }

  static get joinAttrsKey() {
    return [
      'number',
      'designation',
      'street',
      'houseNo',
      'postcode',
      'city',
      'country',
      'salesInvoiceJournal',
      'salesInvoiceTaxCode',
      'salesInvoiceDueDate',
      'salesCreditNoteJournal',
      'salesCreditNoteTaxCode',
      'salesCreditNoteDueDate',
      'purchaseInvoiceJournal',
      'purchaseInvoiceTaxCode',
      'purchaseInvoiceDueDate',
      'purchaseCreditNoteJournal',
      'purchaseCreditNoteTaxCode',
      'purchaseCreditNoteDueDate',
    ];
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'street',
      'houseNo',
      'boxNo',
      'postcode',
      'city',
      'country',
      'customer',
      'potentialCustomer',
      'supplier',
      'potentialSupplier',
      'active',
      'tags',
    ]);
  }

  static get detailAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'street',
      'houseNo',
      'boxNo',
      'postcode',
      'city',
      'country',
      'customer',
      'potentialCustomer',
      'supplier',
      'potentialSupplier',
      'active',
      'locale',
      'tags',
      'url',
      'memo',
      'salesInvoiceJournal',
      'salesInvoiceTaxCode',
      'salesInvoiceDueDate',
      'salesCreditNoteJournal',
      'salesCreditNoteTaxCode',
      'salesCreditNoteDueDate',
      'purchaseInvoiceJournal',
      'purchaseInvoiceTaxCode',
      'purchaseInvoiceDueDate',
      'purchaseCreditNoteJournal',
      'purchaseCreditNoteTaxCode',
      'purchaseCreditNoteDueDate',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'street',
      'houseNo',
      'postcode',
      'city',
      'country',
      'customer',
      'supplier',
      'tags',
    ]);
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '0.5fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-base.common.designation',
          maxWidth: '1fr',
        },
      }),
      street: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.street',
          maxWidth: '1fr',
        },
      }),
      houseNo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.house-number',
          maxWidth: '0.5fr',
          sharable: true,
        },
        column: 'house_no',
      }),
      boxNo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.box-number',
          maxWidth: '0.5fr',
          sharable: true,
        },
        column: 'box_no',
      }),
      postcode: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.postcode',
          maxWidth: '0.5fr',
        },
      }),
      city: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.city',
          maxWidth: '1fr',
        },
      }),
      country: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_COUNTRY, {
        joinPath: ['country!fk_address_country_country'],
        tableProperties: {
          header: 'hit-base.common.country',
          maxWidth: '1fr',
        },
      }),
      customer: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'customer',
          header: 'hit-app.common.customer',
          maxWidth: '0.5fr',
        },
      }),
      potentialCustomer: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'potential_customer',
        tableProperties: {
          header: 'hit-app.address.potential-customer',
          maxWidth: '0.5fr',
          hideByDefault: true,
        },
      }),
      supplier: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'supplier',
          header: 'hit-app.common.supplier',
          maxWidth: '0.5fr',
        },
      }),
      potentialSupplier: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'potential_supplier',
        tableProperties: {
          propertyName: 'potentialSupplier',
          header: 'hit-app.address.potential-supplier',
          maxWidth: '0.5fr',
          hideByDefault: true,
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'active',
          header: 'hit-app.common.active',
          maxWidth: '0.5fr',
        },
      }),
      locale: new HitContainerAttribute(ATTRIBUTE_TYPES.LANGUAGE, {
        tableProperties: {
          header: 'hit-base.common.language',
          maxWidth: '1fr',
        },
      }),
      tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
        tableProperties: {
          header: 'hit-base.common.tags',
          maxWidth: '1fr',
        },
      }),
      url: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {url},
        tableProperties: {
          header: 'hit-app.common.url',
          maxWidth: '1fr',
        },
      }),
      memo: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        tableProperties: {
          header: 'hit-base.common.memo',
        },
      }),
      purchaseInvoiceJournal: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_JOURNAL,
        {
          joinPath: ['journal!fk_address_purchase_invoice_journal_id_journal'],
          fkToUpdate: 'purchase_invoice_journal_id',
          tableProperties: {
            header: 'hit-app.common.invoice-journal',
          },
        }
      ),
      purchaseInvoiceTaxCode: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_TAX_CODE,
        {
          joinPath: [
            'tax_code!fk_address_purchase_invoice_tax_code_id_tax_code',
          ],
          fkToUpdate: 'purchase_invoice_tax_code_id',
          tableProperties: {
            header: 'hit-app.common.invoice-tax-code',
          },
        }
      ),
      purchaseInvoiceDueDate: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_DUE_DATE,
        {
          joinPath: [
            'due_date!fk_address_purchase_invoice_due_date_id_due_date',
          ],
          fkToUpdate: 'purchase_invoice_due_date_id',
          tableProperties: {
            header: 'hit-app.common.invoice-due-date',
          },
        }
      ),
      purchaseCreditNoteJournal: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_JOURNAL,
        {
          joinPath: [
            'journal!fk_address_purchase_creditnote_journal_id_journal',
          ],
          fkToUpdate: 'purchase_creditnote_journal_id',
          tableProperties: {
            header: 'hit-app.common.credit-note-journal',
          },
        }
      ),
      purchaseCreditNoteTaxCode: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_TAX_CODE,
        {
          joinPath: [
            'tax_code!fk_address_purchase_creditnote_tax_code_id_tax_code',
          ],
          fkToUpdate: 'purchase_creditnote_tax_code_id',
          tableProperties: {
            header: 'hit-app.common.credit-note-tax-code',
          },
        }
      ),
      purchaseCreditNoteDueDate: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_DUE_DATE,
        {
          joinPath: [
            'due_date!fk_address_purchase_creditnote_due_date_id_due_date',
          ],
          fkToUpdate: 'purchase_creditnote_due_date_id',
          tableProperties: {
            header: 'hit-app.common.credit-note-due-date',
          },
        }
      ),
      salesInvoiceJournal: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_JOURNAL,
        {
          joinPath: ['journal!fk_address_sale_invoice_journal_id_journal'],
          fkToUpdate: 'sale_invoice_journal_id',
          tableProperties: {
            header: 'hit-app.common.invoice-journal',
          },
        }
      ),
      salesInvoiceTaxCode: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_TAX_CODE,
        {
          joinPath: ['tax_code!fk_address_sale_invoice_tax_code_id_tax_code'],
          fkToUpdate: 'sale_invoice_tax_code_id',
          tableProperties: {
            header: 'hit-app.common.invoice-tax-code',
          },
        }
      ),
      salesInvoiceDueDate: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_DUE_DATE,
        {
          joinPath: ['due_date!fk_address_sale_invoice_due_date_id_due_date'],
          fkToUpdate: 'sale_invoice_due_date_id',
          tableProperties: {
            header: 'hit-app.common.invoice-due-date',
          },
        }
      ),
      salesCreditNoteJournal: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_JOURNAL,
        {
          joinPath: ['journal!fk_address_sale_creditnote_journal_id_journal'],
          fkToUpdate: 'sale_creditnote_journal_id',
          tableProperties: {
            header: 'hit-app.common.credit-note-journal',
          },
        }
      ),
      salesCreditNoteTaxCode: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_TAX_CODE,
        {
          joinPath: [
            'tax_code!fk_address_sale_creditnote_tax_code_id_tax_code',
          ],
          fkToUpdate: 'sale_creditnote_tax_code_id',
          tableProperties: {
            header: 'hit-app.common.credit-note-tax-code',
          },
        }
      ),
      salesCreditNoteDueDate: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_DUE_DATE,
        {
          joinPath: [
            'due_date!fk_address_sale_creditnote_due_date_id_due_date',
          ],
          fkToUpdate: 'sale_creditnote_due_date_id',
          tableProperties: {
            header: 'hit-app.common.credit-note-due-date',
          },
        }
      ),
    };
  }

  /**
   * For the address entity, we need to check if it nos the address of the owning company
   * This address can not be deleted because it is used for the transaction
   */
  async delete(router) {
    await DataService.read('config', {
      attributes: 'value',
      filters: {label: 'eq.company.address.id'},
    }).then(async (response) => {
      if (response.data.length > 0) {
        const companyAddressId = response.data[0].value;
        if (companyAddressId === this.id) {
          useNotificationsStore().insertBannerNotification(
            translate('hit-base.banners.cannot-delete-main-company-address')
          );
          return;
        }
      }
      await super.delete(router);
    });
  }
}
