<template>
  <div>
    <div class="sm:hidden pt-2">
      <hit-select
        v-if="tabs && activeTabId"
        :value="activeTabId"
        :options="tabs"
        :label-fields="['title']"
        :clearable="false"
        @change="setTabVisible"
      />
    </div>
    <div class="hidden sm:block">
      <div>
        <nav class="inline-grid grid-flow-col gap-1">
          <div
            v-for="tab in tabs"
            :key="tab.id"
            class="gap-1 inline-flex items-center py-1 px-1 font-medium text-sm leading-5"
            :class="{
              'border-transparent-panel text-light hover:border-input focus:outline-none focus:text-default focus:border-gray-300': !tab.visible,
              'border-input-focus text-default focus:outline-none focus:text-default focus:border-input-focus':
                tab.visible,
              'cursor-pointer border-b-2 hover:text-default': !tab.disabled,
              'cursor-not-allowed': tab.disabled,
            }"
            @click="setTabVisible(tab.id)"
          >
            <hit-icon
              v-if="tab.icon"
              :icon="tab.icon"
              size="sm"
            />
            <span>{{ tab.title }}</span>
          </div>
        </nav>
      </div>
    </div>
    <div class="mt-2 w-full">
      <!-- @slot Content of the tab -->
      <slot />
    </div>
  </div>
</template>

<script>
import HitSelect from '../form/input/HitSelect.vue';
import HitIcon from '../icon/HitIcon.vue';

export default {
  name: 'HitTabs',
  components: {HitSelect, HitIcon},
  props: {},
  data() {
    return {
      tabs: [],
    };
  },
  computed: {
    activeTabId() {
      const activeTab = this.tabs.find((x) => x.visible);
      if (activeTab) {
        return activeTab.id;
      } else {
        return null;
      }
    },
  },
  beforeMount() {
    try {
      if (Array.isArray(this.$slots.default()[0].children)) {
        this.tabs = this.$slots
          .default()[0]
          .children.filter((x) => x.type.name === 'HitTab')
          .map((item) => item.props);
      } else {
        this.tabs = this.$slots
          .default()
          .filter((x) => x.type.name === 'HitTab')
          .map((item) => item.props);
      }
    } catch (e) {
      this.tabs = [];
    }
  },
  methods: {
    setTabVisible(tabId) {
      const selectedTab = this.tabs.find((tab) => tab.id === tabId);
      if (selectedTab.disabled) return;
      this.tabs.forEach((tab) => (tab.visible = tab.id === tabId));
      this.$emit('tabSelect', tabId);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.disabled {
  pointer-events: None;
  cursor: pointer;
}

.disabled:hover {
  cursor: pointer !important;
}
</style>
