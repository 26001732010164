<template>
  <div>
    <!-- Section -->
    <div
      v-if="componentType === 'section'"
      class="rounded border grid cursor-move"
      :class="{
        'border-danger': (!designation && editable) || alwaysInvalid,
        'border-entity': !((!designation && editable) || alwaysInvalid),
      }"
      :title="componentName"
    >
      <div
        class="bg-section-header text-form-entity leading-8 text-lg component-title px-2"
        :class="{
          editable: editable,
        }"
      >
        <div class="px-2">
          {{ designation || componentName }}
        </div>
        <hit-icon
          v-if="editable"
          icon="edit"
          :tooltip="t('hit-base.common.edit')"
          clickable
          @click="onEditClick"
        />
        <hit-icon
          v-if="editable"
          icon="trash"
          color="danger"
          :tooltip="t('hit-components.common.delete')"
          clickable
          @click="onDeleteClick"
        />
      </div>
      <div class="content p-2">
        <slot />
      </div>
    </div>

    <!-- Text input -->
    <div
      v-if="
        [
          'text-input',
          'int-input',
          'number-input',
          'textarea-input',
          'richtext-input',
        ].indexOf(componentType) !== -1
      "
      class="component-title"
      :class="{
        editable: editable,
      }"
      :title="componentName"
    >
      <div
        class="px-2 grid items-center leading-8 text-lg rounded border cursor-move"
        :class="{
          'border-danger': (!designation && editable) || alwaysInvalid,
          'border-entity': !((!designation && editable) || alwaysInvalid),
        }"
      >
        {{ designation || componentName }}
      </div>
      <hit-icon
        v-if="editable"
        icon="edit"
        :tooltip="t('hit-base.common.edit')"
        clickable
        @click="onEditClick"
      />
      <hit-icon
        v-if="editable"
        icon="trash"
        color="danger"
        :tooltip="t('hit-components.common.delete')"
        clickable
        @click="onDeleteClick"
      />
    </div>

    <!-- Checkbox input -->
    <div
      v-if="componentType === 'checkbox-input'"
      class="component-title"
      :class="{
        editable: editable,
      }"
      :title="componentName"
    >
      <div
        class="leading-8 text-lg cursor-move flex items-center gap-2 rounded border px-2"
        :class="{
          'border-danger': (!designation && editable) || alwaysInvalid,
          'border-entity': !((!designation && editable) || alwaysInvalid),
        }"
      >
        <input
          type="checkbox"
          class="hit-checkbox"
          :disabled="true"
        >
        {{ designation || componentName }}
      </div>
      <hit-icon
        v-if="editable"
        icon="edit"
        :tooltip="t('hit-components.common.edit')"
        clickable
        @click="onEditClick"
      />
      <hit-icon
        v-if="editable"
        icon="trash"
        color="danger"
        :tooltip="t('hit-components.common.delete')"
        clickable
        @click="onDeleteClick"
      />
    </div>

    <!-- Entity input -->
    <div
      v-if="componentType === 'entity-input'"
      class="component-title"
      :class="{
        editable: editable,
      }"
      :title="componentName"
    >
      <div
        class="pl-2 select-input grid items-center leading-8 text-lg rounded border cursor-move"
        :class="{
          'border-danger': (!designation && editable) || alwaysInvalid,
          'border-entity': !((!designation && editable) || alwaysInvalid),
        }"
      >
        {{ designation || componentName }}
        <div
          class="w-8 py-1 pl-1 pr-1 text-center cursor-pointer inline-grid items-center border-l border-entity"
        >
          <hit-icon
            icon="expand-bottom"
            class="w-6 h-6 outline-none focus:outline-none inline-grid justify-center items-center"
          />
        </div>
      </div>
      <hit-icon
        v-if="editable"
        icon="edit"
        :tooltip="t('hit-base.common.edit')"
        clickable
        @click="onEditClick"
      />
      <hit-icon
        v-if="editable"
        icon="trash"
        color="danger"
        :tooltip="t('hit-components.common.delete')"
        clickable
        @click="onDeleteClick"
      />
    </div>

    <!-- Date input -->
    <div
      v-if="componentType === 'date-input'"
      class="component-title"
      :class="{
        editable: editable,
      }"
      :title="componentName"
    >
      <div
        class="px-2 date-input grid items-center gap-2 leading-8 text-lg rounded border cursor-move"
        :class="{
          'border-danger': (!designation && editable) || alwaysInvalid,
          'border-entity': !((!designation && editable) || alwaysInvalid),
        }"
      >
        <hit-icon icon="calendar" />
        {{ designation || componentName }}
      </div>
      <hit-icon
        v-if="editable"
        icon="edit"
        :tooltip="t('hit-base.common.edit')"
        clickable
        @click="onEditClick"
      />
      <hit-icon
        v-if="editable"
        icon="trash"
        color="danger"
        :tooltip="t('hit-components.common.delete')"
        clickable
        @click="onDeleteClick"
      />
    </div>

    <!-- Location input -->
    <div
      v-if="componentType === 'location-input'"
      class="component-title"
      :class="{
        editable: editable,
      }"
      :title="componentName"
    >
      <div
        class="px-2 date-input grid items-center gap-2 leading-8 text-lg rounded border cursor-move"
        :class="{
          'border-danger': (!designation && editable) || alwaysInvalid,
          'border-entity': !((!designation && editable) || alwaysInvalid),
        }"
      >
        <hit-icon icon="location" />
        {{ designation || componentName }}
      </div>
      <hit-icon
        v-if="editable"
        icon="edit"
        :tooltip="t('hit-base.common.edit')"
        clickable
        @click="onEditClick"
      />
      <hit-icon
        v-if="editable"
        icon="trash"
        color="danger"
        :tooltip="t('hit-components.common.delete')"
        clickable
        @click="onDeleteClick"
      />
    </div>

    <!-- File input -->
    <div
      v-if="componentType === 'file-input'"
      class="component-title"
      :class="{
        editable: editable,
      }"
      :title="componentName"
    >
      <div
        class="px-2 date-input grid items-center gap-2 leading-8 text-lg rounded border cursor-move"
        :class="{
          'border-danger': (!designation && editable) || alwaysInvalid,
          'border-entity': !((!designation && editable) || alwaysInvalid),
        }"
      >
        <hit-icon icon="upload" />
        {{ designation || componentName }}
      </div>
      <hit-icon
        v-if="editable"
        icon="edit"
        :tooltip="t('hit-base.common.edit')"
        clickable
        @click="onEditClick"
      />
      <hit-icon
        v-if="editable"
        icon="trash"
        color="danger"
        :tooltip="t('hit-components.common.delete')"
        clickable
        @click="onDeleteClick"
      />
    </div>

    <!-- Signature input -->
    <div
      v-if="componentType === 'signature-input'"
      class="component-title"
      :class="{
        editable: editable,
      }"
      :title="componentName"
    >
      <div
        class="px-2 date-input grid items-center gap-2 leading-8 text-lg rounded border cursor-move"
        :class="{
          'border-danger': (!designation && editable) || alwaysInvalid,
          'border-entity': !((!designation && editable) || alwaysInvalid),
        }"
      >
        <hit-icon icon="signature" />
        {{ designation || componentName }}
      </div>
      <hit-icon
        v-if="editable"
        icon="edit"
        :tooltip="t('hit-base.common.edit')"
        clickable
        @click="onEditClick"
      />
      <hit-icon
        v-if="editable"
        icon="trash"
        color="danger"
        :tooltip="t('hit-components.common.delete')"
        clickable
        @click="onDeleteClick"
      />
    </div>
    <div
      v-if="componentType === 'list-input'"
      class="rounded border grid cursor-move"
      :class="{
        'border-danger': (!designation && editable) || alwaysInvalid,
        'border-entity': !((!designation && editable) || alwaysInvalid),
      }"
      :title="componentName"
    >
      <div
        class="bg-section-header text-form-entity leading-8 text-lg component-title px-2"
        :class="{
          editable: editable,
        }"
      >
        <div class="px-2">
          {{ designation || componentName }}
        </div>
        <hit-icon
          v-if="editable"
          icon="edit"
          :tooltip="t('hit-base.common.edit')"
          clickable
          @click="onEditClick"
        />
        <hit-icon
          v-if="editable"
          icon="trash"
          color="danger"
          :tooltip="t('hit-components.common.delete')"
          clickable
          @click="onDeleteClick"
        />
      </div>
      <div class="content p-2">
        <slot />
      </div>
    </div>
    <div
      v-if="componentType === 'radio-input'"
      class="component-title"
      :class="{
        editable: editable,
      }"
      :title="componentName"
    >
      <div
        class="px-2 date-input grid items-center gap-2 leading-8 text-lg rounded border cursor-move"
        :class="{
          'border-danger': (!designation && editable) || alwaysInvalid,
          'border-entity': !((!designation && editable) || alwaysInvalid),
        }"
      >
        <hit-icon icon="radio-checked" />
        {{ designation || componentName }}
      </div>
      <hit-icon
        v-if="editable"
        icon="edit"
        :tooltip="t('hit-base.common.edit')"
        clickable
        @click="onEditClick"
      />
      <hit-icon
        v-if="editable"
        icon="trash"
        color="danger"
        :tooltip="t('hit-components.common.delete')"
        clickable
        @click="onDeleteClick"
      />
    </div>
    <div
      v-if="componentType === 'hours-input'"
      class="component-title"
      :class="{
        editable: editable,
      }"
      :title="componentName"
    >
      <div
        class="px-2 date-input grid items-center gap-2 leading-8 text-lg rounded border cursor-move"
        :class="{
          'border-danger': (!designation && editable) || alwaysInvalid,
          'border-entity': !((!designation && editable) || alwaysInvalid),
        }"
      >
        <hit-icon icon="hours-icon" />
        {{ designation || componentName }}
      </div>
      <hit-icon
        v-if="editable"
        icon="edit"
        :tooltip="t('hit-base.common.edit')"
        clickable
        @click="onEditClick"
      />
      <hit-icon
        v-if="editable"
        icon="trash"
        color="danger"
        :tooltip="t('hit-components.common.delete')"
        clickable
        @click="onDeleteClick"
      />
    </div>
    <div
      v-if="componentType === 'time-input'"
      class="component-title"
      :class="{
        editable: editable,
      }"
      :title="componentName"
    >
      <div
        class="px-2 date-input grid items-center gap-2 leading-8 text-lg rounded border cursor-move"
        :class="{
          'border-danger': (!designation && editable) || alwaysInvalid,
          'border-entity': !((!designation && editable) || alwaysInvalid),
        }"
      >
        <hit-icon icon="hours-icon" />
        {{ designation || componentName }}
      </div>
      <hit-icon
        v-if="editable"
        icon="edit"
        :tooltip="t('hit-base.common.edit')"
        clickable
        @click="onEditClick"
      />
      <hit-icon
        v-if="editable"
        icon="trash"
        color="danger"
        :tooltip="t('hit-components.common.delete')"
        clickable
        @click="onDeleteClick"
      />
    </div>
  </div>
</template>

<script>
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitFormDefinitionBuilderComponent',
  components: {},
  setup() {
    const {t} = useI18n();
    return {t};
  },
  props: {
    /**
     * Type of the component to render
     */
    componentType: {
      type: String,
      required: true,
    },
    /**
     * Name of the component
     */
    designation: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Enables to edit the component
     */
    editable: {
      type: Boolean,
      default: false,
    },
    /**
     * Name of the type of the component
     */
    componentName: {
      type: String,
      required: true,
    },
    alwaysInvalid: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onEditClick() {
      /**
       * When the component gets edited
       */
      this.$emit('edit');
    },
    onDeleteClick() {
      /**
       * When the component gets deleted
       */
      this.$emit('delete');
    },
  },
};
</script>

<style scoped>
.content {
  @apply grid gap-2;
  min-height: 3rem;
}

.component-title {
  @apply grid items-center gap-2;
  grid-template-columns: 1fr;
}

.editable {
  grid-template-columns: 1fr auto auto;
}

.select-input {
  grid-template-columns: 1fr auto;
}

.date-input {
  grid-template-columns: max-content auto;
}
</style>
