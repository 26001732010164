<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-equipment')"
    route="equipment"
    :table-attributes="tableAttributes"
    auto-complete-id="equipment"
    :data-list-config="dataListConfig"
    :default-sort="{property: 'no', order: 'asc'}"
    :only-load-active-records="onlyLoadActiveRecords"
    :custom-filters="customFilters"
    @item-selected="selectItem"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {Equipment} from '../../../models';

export default {
  name: 'HitEquipmentBrowser',
  components: {
    HitEntityBrowser,
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  props: {
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      tableAttributes: Equipment.browserDisplayAttrs,
      dataListConfig: {},
    };
  },
  computed: {
    customFilters() {
      const filters = {};
      return filters;
    },
  },
  methods: {
    selectItem(data) {
      const equipment = new Equipment(data);
      this.$emit('item-selected', equipment);
    },
  },
  emits: ['itemSelected'],
};
</script>
