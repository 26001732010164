import {BaseModel, MultiLanguageDesignationModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import MultiLanguageField from './MultiLanguageField';
import {HitOnlineEnums} from 'hit-online-web-ui/src/helpers/constants';
import {translate} from '../../plugins/i18n/i18n';

export default class Unit extends MultiLanguageDesignationModel {
  constructor({
    id,
    number,
    designationFrBe = undefined,
    designationDeBe = undefined,
    designationNlBe = undefined,
    designationFrLu = undefined,
    designationDeLu = undefined,
    designationFrFr = undefined,
    designationDeDe = undefined,
    designationNlNl = undefined,
    designationEnGb = undefined,
    designationEnIn = undefined,
    designationMrIn = undefined,
    designationHiIn = undefined,
    unitFrBe = undefined,
    unitDeBe = undefined,
    unitNlBe = undefined,
    unitFrLu = undefined,
    unitDeLu = undefined,
    unitFrFr = undefined,
    unitDeDe = undefined,
    unitNlNl = undefined,
    unitEnGb = undefined,
    unitEnIn = undefined,
    unitMrIn = undefined,
    unitHiIn = undefined,
    category = undefined,
    factor = undefined,
    peppolId = undefined,
  }) {
    super({
      id,
      number,
      designationFrBe,
      designationDeBe,
      designationNlBe,
      designationFrLu,
      designationDeLu,
      designationFrFr,
      designationDeDe,
      designationNlNl,
      designationEnGb,
      designationEnIn,
      designationMrIn,
      designationHiIn,
    });
    this.units = new MultiLanguageField({
      unitFrBe,
      unitDeBe,
      unitNlBe,
      unitFrLu,
      unitDeLu,
      unitFrFr,
      unitDeDe,
      unitNlNl,
      unitEnGb,
      unitEnIn,
      unitMrIn,
      unitHiIn,
    });
    this.unit = this.units.value;
    this.category = category
      ? HitOnlineEnums.getEnumValue('UNIT_CATEGORY', category, translate)
      : null;
    this.factor = factor;
    this.peppolId = peppolId;
  }

  get fullDesignation() {
    return this.unit;
  }

  static get fkName() {
    return 'unit_id';
  }
  static get apiRoute() {
    return 'unit';
  }
  static get listPageRoute() {
    return 'unitList';
  }
  static get detailPageRoute() {
    return 'unitList';
  }
  static get createInitColumns() {
    return {};
  }
  static get config() {
    const config = BaseModel.config;
    config['addable'] = false;
    return config;
  }
  get fkName() {
    return Unit.fkName;
  }
  get apiRoute() {
    return Unit.apiRoute;
  }
  get detailPageRoute() {
    return Unit.detailPageRoute;
  }
  get listPageRoute() {
    return Unit.listPageRoute;
  }
  get config() {
    return Unit.config;
  }

  static get browserDisplayAttrs() {
    return this.getAttributes(
      [
        'number',
        this.attributeUserLocale('designation'),
        this.attributeCompanyLocale('designation'),
        this.attributeUserLocale('unit'),
        this.attributeCompanyLocale('unit'),
      ],
      ['category', 'peppolId']
    );
  }

  static get joinAttrsKey() {
    return [
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      this.attributeUserLocale('unit'),
      this.attributeCompanyLocale('unit'),
    ];
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '0.5fr',
        },
      }),
      ...this.generateMultiLanguageField({
        key: 'designation',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.designation',
      }),
      ...this.generateMultiLanguageField({
        key: 'unit',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.unit',
      }),
      category: new HitContainerAttribute(ATTRIBUTE_TYPES.ENUMERATION, {
        enumType: 'UNIT_CATEGORY',
        column: 'category',
        tableProperties: {
          header: 'hit-app.common.category',
          maxWidth: '1fr',
        },
      }),
      factor: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 4,
        decimalDefaultValue: 1,
        column: 'factor',
        tableProperties: {
          header: 'hit-app.common.factor',
          maxWidth: '1fr',
        },
      }),
      peppolId: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'peppol_id',
        tableProperties: {
          header: 'hit-app.common.peppol-id',
          maxWidth: '0.7fr',
        },
      }),
    };
  }
}
