import {makeEntityStore} from '../../../helpers/factory';
import {FormTemplate} from '../../../../models';
import {BaseRootEntityService} from '../../../../api';
import {formTemplateSchema} from '../../../../models/hit/form/schema';

export default makeEntityStore({
  modelCls: FormTemplate,
  service: new BaseRootEntityService('document', FormTemplate),
  schema: formTemplateSchema,
});
