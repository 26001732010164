<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-period')"
    route="period"
    auto-complete-id="period"
    :table-attributes="tableAttributes"
    :default-sort="{property: 'no', order: 'asc'}"
    :only-load-active-records="onlyLoadActiveRecords"
    filter-id-column="closed"
    filter-id="false"
    :data-list-config="dataListConfig"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {Period} from '../../../models';
export default {
  name: 'HitPeriodBrowser',
  components: {
    HitEntityBrowser,
  },
  setup() {
    const {d, t} = useI18n();
    return {d, t};
  },
  props: {
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      tableAttributes: Period.browserDisplayAttrs,
      dataListConfig: {
        zone1CustomFunction: (item) =>
          `${this.d(item.beginDate, 'short')} - ${this.d(
            item.endDate,
            'short'
          )}`,
        zone2Field: 'designation',
      },
    };
  },
  methods: {
    selectItem(data) {
      const period = new Period(data);
      this.$emit('item-selected', period);
    },
  },
  emits: ['itemSelected'],
};
</script>
