<template>
  <div
    class="flex flex-col bg-panel pl-2 pr-2 pb-1"
    :class="{
      'w-[32rem]': responsiveBreakpointSm,
      'w-full': !responsiveBreakpointSm,
    }"
  >
    <div v-if="notifications.length > 0">
      <hit-notification
        v-for="notification in reversedNotifications"
        :key="notification.notification_id"
        :notification="notification"
        @clear="(id) => $emit('clear', id)"
      />
    </div>
    <div
      v-else
      class="w-full h-16 border border-table flex justify-center items-center mt-1"
    >
      <p class="text-md font-bold">
        {{ t('hit-components.common.no-notifications') }}
      </p>
    </div>
  </div>
</template>
<script>
import HitNotification from './HitNotification.vue';
import {useI18n} from 'vue-i18n';
import HitBreakpointsMixin from '@hit/components/src/mixins/breakpoints/HitBreakpointsMixin';
export default {
  name: 'HitNotificationList',
  components: {
    HitNotification,
  },
  mixins: [HitBreakpointsMixin],
  props: {
    notifications: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    reversedNotifications() {
      const notifications = [...this.notifications];
      return notifications.reverse();
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
};
</script>
