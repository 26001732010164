import {BaseModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';
import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';

export default class FileDocument extends BaseModel {
  constructor({id, number, designation, active, memo, author, mimeType, size}) {
    super(id, number, designation);
    this.active = active;
    this.memo = memo;
    this.author = author;
    this.mimeType = mimeType;
    this.size = size;
  }

  static get fkName() {
    return 'document_id';
  }
  static get apiRoute() {
    return 'document';
  }
  static get detailPageRoute() {
    return undefined;
  }
  static get listPageRoute() {
    return 'fileConfig';
  }
  get fkName() {
    return File.fkName;
  }
  get apiRoute() {
    return File.apiRoute;
  }
  get listPageRoute() {
    return File.listPageRoute;
  }
  get detailPageRoute() {
    return File.detailPageRoute;
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'designation',
      'filename',
      'id',
      'mimeType',
      'size',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes(
      ['designation', 'filename', 'mimeType', 'size'],
      []
    );
  }

  static get entityListDisplayAttrs() {
    return this.getAttributes(['filename', 'mimeType', 'size']);
  }

  static get allAttributes() {
    return {
      // We need to add ID to the attributes to be able to display the value in the table
      id: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        readOnly: true,
        tableProperties: {
          header: 'ID',
          maxWidth: '2fr',
          readOnly: true,
        },
      }),
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          mainSearch: true,
          maxWidth: '1fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-base.common.designation',
          maxWidth: '2fr',
        },
      }),
      filename: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        readOnly: true,
        tableProperties: {
          readOnly: true,
          header: 'hit-app.view.filename',
          maxWidth: '2fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'active',
          header: 'hit-app.common.active',
          maxWidth: '0.5fr',
        },
      }),
      memo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-base.common.memo',
        },
      }),
      author: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_STAFF, {
        column: 'author_id',
        joinPath: ['fk_document_author_id_staff'],
        fkToUpdate: 'author_id',
        tableProperties: {
          header: 'hit-base.common.author',
        },
      }),
      mimeType: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'mime_type',
        readOnly: true,
        tableProperties: {
          header: 'Mime',
          maxWidth: '1fr',
          readOnly: true,
        },
      }),
      size: new HitContainerAttribute(ATTRIBUTE_TYPES.INT, {
        readOnly: true,
        transform: (x) => HitUtils.convertFileSize(x),
        tableProperties: {
          header: 'hit-app.view.size',
          maxWidth: '1fr',
          readOnly: true,
        },
      }),
    };
  }
}
