<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-address')"
    route="address"
    auto-complete-id="address"
    :table-attributes="browserAttributes"
    :default-sort="{property: 'no', order: 'asc'}"
    :filter-id="filterId"
    :filter-id-column="filterIdColumn"
    :only-load-active-records="onlyLoadActiveRecords"
    @item-selected="selectItem"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {Address} from '../../../models';

export default {
  name: 'HitAddressBrowser',
  computed: {
    browserAttributes() {
      return Address.browserDisplayAttrs;
    },
  },
  components: {
    HitEntityBrowser,
  },
  props: {
    filterId: {
      type: String,
      required: false,
    },
    filterIdColumn: {
      type: String,
      required: false,
    },
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
    tagFilters: {
      type: Array,
      required: false,
      default: null,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  methods: {
    selectItem(data) {
      const address = new Address(data);
      this.$emit('item-selected', address);
    },
  },
};
</script>
