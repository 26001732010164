import BaseModel from './BaseModel';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {Address, Journal, Period, ProjectPart} from './index';
import Staff from './Staff';

export default class Transaction extends BaseModel {
  constructor({
    id,
    documentNumber,
    designation,
    address = undefined,
    projectPart = undefined,
    journal = undefined,
    period = undefined,
    responsible = undefined,
    documentDate = undefined,
    dueDate = undefined,
    header = '',
    description = '',
    footer = '',
    transacted = false,
    tags = [],
  }) {
    super(id, documentNumber, designation);
    this.address = address ? new Address(address) : null;
    this.projectPart = projectPart ? new ProjectPart(projectPart) : null;
    this.journal = journal ? new Journal(journal) : null;
    this.period = period ? new Period(period) : null;
    this.responsible = responsible ? new Staff(responsible) : null;
    this.documentDate = documentDate;
    this.dueDate = dueDate;
    this.header = header;
    this.description = description;
    this.footer = footer;
    this.transacted = transacted;
    this.tags = tags;
  }

  static get config() {
    return {
      addable: true,
      duplicatable: false,
      importable: false,
      browsable: true,
      deletable: false,
    };
  }
  get config() {
    return Transaction.config;
  }

  static get printTemplateType() {
    return 'transaction';
  }
  get printTemplateType() {
    return Transaction.printTemplateType;
  }

  static get fkName() {
    return 'transaction_id';
  }
  static get apiRoute() {
    return 'transaction';
  }
  static get detailPageRoute() {
    return 'transactionDetail';
  }
  static get listPageRoute() {
    return 'transactionList';
  }
  get fkName() {
    return Transaction.fkName;
  }
  get apiRoute() {
    return Transaction.apiRoute;
  }
  get detailPageRoute() {
    return Transaction.detailPageRoute;
  }
  get listPageRoute() {
    return Transaction.listPageRoute;
  }

  static get joinAttrsKey() {
    return ['documentNumber', 'designation', 'address', 'projectPart'];
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'designation',
      'documentNumber',
      'journal',
      'documentDate',
      'address',
      'projectPart',
      'responsible',
      'transactionAmount',
      'tags',
    ]);
  }
  static get entityListDisplayAttrs() {
    return this.getAttributes([
      'designation',
      'documentNumber',
      'documentDate',
      'journal',
      'tags',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'designation',
      'journal',
      'period',
      'documentNumber',
      'documentDate',
      'address',
      'projectPart',
      'responsible',
      'tags',
    ]);
  }

  static get createInitColumns() {
    return {
      designation: '',
      document_date: new Date(),
      due_date: new Date(),
    };
  }

  static get allAttributes() {
    return {
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.designation',
          maxWidth: '2fr',
        },
      }),
      documentNumber: new HitContainerAttribute(ATTRIBUTE_TYPES.INT, {
        column: 'document_no',
        tableProperties: {
          header: 'hit-app.transaction.document-number',
          maxWidth: '0.75fr',
        },
      }),
      address: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_ADDRESS, {
        column: 'address_id',
        joinPath: ['address!fk_transaction_address_id_address'],
        tableProperties: {
          header: 'hit-base.common.address',
        },
      }),
      projectPart: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_PROJECT_PART, {
        column: 'project_part_id',
        joinPath: ['project_part!fk_transaction_project_part_id_project_part'],
        tableProperties: {
          header: 'hit-base.common.project-part',
        },
      }),
      journal: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_JOURNAL, {
        column: 'journal_id',
        joinPath: ['journal!fk_transaction_journal_id_journal'],
        tableProperties: {
          header: 'hit-app.common.journal',
          maxWidth: '1fr',
        },
      }),
      period: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_PERIOD, {
        column: 'period_id',
        joinPath: ['period!fk_transaction_period_id_period'],
        tableProperties: {
          header: 'hit-app.common.period',
        },
      }),
      responsible: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_STAFF, {
        column: 'staff_id',
        joinPath: ['staff!fk_transaction_staff_id_staff'],
        tableProperties: {
          header: 'hit-app.common.responsible',
        },
      }),
      documentDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'document_date',
        tableProperties: {
          header: 'hit-app.transaction.document-date',
          maxWidth: '1fr',
        },
      }),
      dueDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'due_date',
        tableProperties: {
          header: 'hit-app.transaction.due-date',
        },
      }),
      header: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        tableProperties: {
          header: 'hit-app.transaction.header',
          maxWidth: '1fr',
        },
      }),
      description: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        tableProperties: {
          header: 'hit-base.common.description',
          maxWidth: '1fr',
        },
      }),
      footer: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        tableProperties: {
          header: 'hit-app.transaction.footer',
          maxWidth: '1fr',
        },
      }),
      transacted: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'transacted',
        },
      }),
      tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
        tableProperties: {
          header: 'hit-base.common.tags',
          maxWidth: '1fr',
        },
      }),
      transactionAmount: new HitContainerAttribute(
        ATTRIBUTE_TYPES.AGGREGATE_FUNCTION,
        {
          column: 'transaction_item(sales_amount.sum())',
          transform: (item) =>
            Array.isArray(item) && item[0] && item[0].sum ? item[0].sum : 0,
          tableProperties: {
            header: 'hit-app.common.amount',
            maxWidth: '1fr',
          },
          forcedType: 'decimal',
        }
      ),
    };
  }
}
