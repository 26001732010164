import {Address, BaseModel, Resource} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';

export default class ResourceSupplier extends BaseModel {
  constructor({id, supplier, resource, price}) {
    super(id, '', '');
    this.supplier = supplier ? new Address(supplier) : null;
    this.resource = resource ? new Resource(resource) : null;
    this.price = price;
  }

  static get fkName() {
    return null;
  }
  static get apiRoute() {
    return 'resource_supplier';
  }
  static get listPageRoute() {
    return null;
  }
  static get detailPageRoute() {
    return null;
  }
  static get createInitColumns() {
    return {};
  }
  static get orderKey() {
    return 'price';
  }
  get fkName() {
    return ResourceSupplier.fkName;
  }
  get apiRoute() {
    return ResourceSupplier.apiRoute;
  }
  get detailPageRoute() {
    return ResourceSupplier.detailPageRoute;
  }
  get listPageRoute() {
    return ResourceSupplier.listPageRoute;
  }
  get createInitColumns() {
    return ResourceSupplier.createInitColumns;
  }

  static get entityListDisplayAttrs() {
    return this.getAttributes(['supplier', 'price']);
  }

  static get allAttributes() {
    return {
      supplier: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_ADDRESS, {
        column: 'address_id',
        joinPath: ['fk_resource_supplier_address_id_address'],
        customFilters: {
          supplier: 'is.true',
        },
        tableProperties: {
          header: 'hit-app.common.supplier',
        },
      }),
      resource: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_RESOURCE, {
        column: 'resource_id',
        joinPath: ['fk_resource_supplier_resource_id_resource'],
        tableProperties: {
          header: 'hit-base.common.resource',
        },
      }),
      price: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 2,
        decimalDefaultValue: 0,
        tableProperties: {
          header: 'hit-app.common.price',
          maxWidth: '1fr',
        },
      }),
    };
  }
}
