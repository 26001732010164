import {Address, BaseModel, Equipment, ProjectPart, Resource} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';
import {HitActionUtils} from '@hit/components/src/utils/action/HitActionUtils';
import Staff from './Staff';
import {useActionStore} from '../../store';
import {HitOnlineEnums} from 'hit-online-web-ui/src/helpers/constants';
import {translate} from '../../plugins/i18n/i18n';

export default class Action extends BaseModel {
  constructor({
    id,
    number,
    designation,
    dueDate = undefined,
    author = undefined,
    assignee = undefined,
    importance = undefined,
    urgency = undefined,
    status = undefined,
    tags = [],
    url = '',
    address = undefined,
    staff = undefined,
    equipment = undefined,
    resource = undefined,
    projectPart = undefined,
    privateFlag = false,
  }) {
    super(id, number, designation);
    this.dueDate = dueDate;
    this.author = author ? new Staff(author) : null;
    this.assignee = assignee ? new Staff(assignee) : null;
    this.importance = importance;
    this.urgency = urgency;
    this.tags = tags;
    this.url = url;
    this.address = address ? new Address(address) : null;
    this.staff = staff ? new Staff(staff) : null;
    this.equipment = equipment ? new Equipment(equipment) : null;
    this.resource = resource ? new Resource(resource) : null;
    this.projectPart = projectPart ? new ProjectPart(projectPart) : null;
    this.status = status
      ? HitOnlineEnums.getEnumValue('ACTION_STATUS', status, translate)
      : null;
    this.privateFlag = privateFlag;
  }

  static get config() {
    const config = BaseModel.config;
    config['importable'] = false;
    return config;
  }

  get config() {
    return Action.config;
  }

  static get fkName() {
    return 'action_id';
  }
  static get apiRoute() {
    return 'action';
  }
  static get detailPageRoute() {
    return 'actionDetail';
  }
  static get listPageRoute() {
    return 'actionList';
  }
  get fkName() {
    return Action.fkName;
  }
  get apiRoute() {
    return Action.apiRoute;
  }
  get listPageRoute() {
    return Action.listPageRoute;
  }
  get detailPageRoute() {
    return Action.detailPageRoute;
  }

  static get joinAttrsKey() {
    return ['number', 'designation', 'dueDate'];
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'dueDate',
      'author',
      'assignee',
      'importance',
      'urgency',
      'status',
      'tags',
    ]);
  }

  static get infoAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'dueDate',
      'url',
      'importance',
      'urgency',
      'tags',
      'assignee',
      'estimatedDuration',
      'author',
      'status',
      'address',
      'staff',
      'equipment',
      'resource',
      'projectPart',
      'privateFlag',
    ]);
  }

  static get detailAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'description',
      'assignee',
      'author',
      'status',
      'address',
      'staff',
      'equipment',
      'resource',
      'projectPart',
    ]);
  }

  static get entityListDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'dueDate',
      'assignee',
      'importance',
      'urgency',
      'status',
      'tags',
    ]);
  }

  static get linkedEntitiesAttrs() {
    return this.getAttributes([
      'address',
      'staff',
      'equipment',
      'resource',
      'projectPart',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'author',
      'assignee',
      'dueDate',
      'status',
      'tags',
    ]);
  }

  static get createInitColumns() {
    return {
      no: HitActionUtils.generateRandomNo(),
      designation: '',
      due_date: HitActionUtils.getDefaultDueDate(),
      status: HitActionUtils.Status.OPENED,
    };
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '0.5fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-base.common.designation',
          maxWidth: '1fr',
        },
      }),
      description: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        tableProperties: {
          header: 'hit-base.common.description',
          maxWidth: '1fr',
        },
      }),
      dueDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'due_date',
        tableProperties: {
          header: 'hit-app.common.due-date',
          maxWidth: '1fr',
        },
      }),
      author: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_STAFF, {
        column: 'author_id',
        joinPath: ['staff!fk_action_creation_user_id_staff'],
        fkToUpdate: 'author_id',
        tableProperties: {
          header: 'hit-base.common.author',
        },
      }),
      assignee: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_STAFF, {
        column: 'assignee_id',
        joinPath: ['staff!fk_action_assignee_id_staff'],
        fkToUpdate: 'assignee_id',
        tableProperties: {
          header: 'hit-base.common.assignee',
          maxWidth: '1fr',
        },
      }),
      importance: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        tableProperties: {
          header: 'hit-app.common.importance',
          maxWidth: '1fr',
          hideByDefault: true,
        },
      }),
      urgency: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        tableProperties: {
          header: 'hit-app.common.urgency',
          maxWidth: '1fr',
          hideByDefault: true,
        },
      }),
      url: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.url',
        },
      }),
      status: new HitContainerAttribute(ATTRIBUTE_TYPES.ENUMERATION, {
        enumType: 'ACTION_STATUS',
        tableProperties: {
          header: 'hit-app.common.status',
          maxWidth: '0.75fr',
        },
      }),
      tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
        tableProperties: {
          header: 'hit-base.common.tags',
          maxWidth: '1fr',
        },
      }),
      address: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_ADDRESS, {
        joinPath: ['address!fk_action_address_id_address'],
        column: 'address_id',
        tableProperties: {
          header: 'hit-base.common.address',
        },
      }),
      staff: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_STAFF, {
        joinPath: ['staff!fk_action_staff_id_staff'],
        column: 'staff_id',
        tableProperties: {
          header: 'hit-base.common.staff',
        },
      }),
      equipment: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_EQUIPMENT, {
        joinPath: ['equipment!fk_action_equipment_id_equipment'],
        column: 'equipment_id',
        tableProperties: {
          header: 'hit-base.common.equipment',
        },
      }),
      resource: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_RESOURCE, {
        joinPath: ['resource!fk_action_resource_id_resource'],
        column: 'resource_id',
        tableProperties: {
          header: 'hit-base.common.resource',
        },
      }),
      projectPart: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_PROJECT_PART, {
        joinPath: ['project_part!fk_action_project_part_id_project_part'],
        column: 'project_part_id',
        tableProperties: {
          header: 'hit-base.common.project-part',
        },
      }),
      estimatedDuration: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'estimated_duration',
        tableProperties: {
          header: 'hit-app.common.estimated-duration',
        },
      }),
      privateFlag: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'private',
        tableProperties: {
          header: 'hit-app.action.private',
        },
      }),
      // document: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
      //   joinPath: ["document!fk_action_document_id_document"],
      //   column: 'document_id',
      //   tableProperties: {
      //     header: "hit-base.common.document"
      //   }
      // }),
      // transaction: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
      //   joinPath: ["resource!fk_action_resource_id_resource"],
      //   column: 'resource_id',
      //   tableProperties: {
      //     header: "hit-base.common.resource"
      //   }
      // }),
      // form: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
      //   joinPath: ["form!fk_action_form_id_form"],
      //   column: 'form_id',
      //   tableProperties: {
      //     header: "hit-base.common.form"
      //   }
      // }),
      // cost: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
      //   joinPath: ["cost!fk_action_cost_id_cost"],
      //   column: 'cost_id',
      //   tableProperties: {
      //     header: "hit-base.common.cost"
      //   }
      // }),
    };
  }

  static get allHistoryAttributes() {
    return {
      date: new HitContainerAttribute(ATTRIBUTE_TYPES.DATETIME, {
        column: 'operation_date',
        tableProperties: {
          header: 'hit-app.common.date',
        },
      }),
      operator: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_STAFF, {
        joinPath: ['staff!fk_action_history_creation_user_id_staff'],
        column: 'creation_user_id',
        tableProperties: {
          header: 'hit-app.action.operator',
        },
      }),
      duration: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        transform: (d) => HitActionUtils.stringifyDuration(d),
        tableProperties: {
          header: 'hit-app.common.duration',
        },
      }),
      operation: new HitContainerAttribute(ATTRIBUTE_TYPES.ENUMERATION, {
        enumType: 'ACTION_OPERATION',
        tableProperties: {
          header: 'hit-app.common.operation',
        },
      }),
      message: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.action.message',
        },
      }),
      assignee: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_STAFF, {
        joinPath: ['staff!fk_action_history_assignee_id_staff'],
        column: 'assignee_id',
        tableProperties: {
          header: 'hit-base.common.assignee',
        },
      }),
      tag: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
        tableProperties: {
          header: 'hit-base.common.tags',
        },
      }),
    };
  }

  async delete(router) {
    useActionStore()
      .deleteAction(this.id)
      .then((success) => {
        if (success) {
          this.redirectToList(router);
        }
      });
  }
}
