<template>
  <hit-base-input
    :label="label"
    :validation-state="fieldValidationState"
    use-custom-height
    :full-width="false"
  >
    <div
      v-if="type === 'text'"
      class="flex flex-col"
    >
      <div
        v-for="language in displayedLanguages"
        :key="language.id"
        class="mb-1"
      >
        <hit-input
          v-model:value="value[getFieldLanguage(language)]"
          :validation-state="validationState[getFieldLanguage(language)]"
          :name="getFieldLanguage(language)"
          :instant-focus="instantFocus"
          :extra-slot-size="sortedLanguages.length > 1 ? '6rem' : 0"
          inline-input
          @input="onInput($event, language)"
          @change="onChange($event, language)"
        >
          <template
            v-if="sortedLanguages.length > 1"
            #extra
          >
            <div class="flex items-center">
              <div class="ml-1 mr-1">
                {{ language.shortDesignation }}
              </div>
              <hit-button
                v-show="language.id === defaultLocale"
                class="h-8 w-8"
                :remove-margin="true"
                :remove-padding="true"
                prefix-icon="language"
                :tooltip="displayTranslationsTooltip"
                :ignore-on-tab="true"
                @click="allLanguagesVisible = !allLanguagesVisible"
              />
            </div>
          </template>
        </hit-input>
      </div>
    </div>
    <div v-else-if="type === 'textarea'">
      <hit-tabs
        v-if="sortedLanguages.length > 1"
        @tabSelect="onTabSelected"
      >
        <hit-tab
          v-for="language in sortedLanguages"
          :id="language.id"
          :key="language.id"
          :title="language.longDesignation"
          :visible="language.id === visibleLanguage"
        >
          <hit-input-text-area
            v-model:value="value[getFieldLanguage(language)]"
            :rows="rows"
            :inline-input="true"
            inline-label-width="1fr"
            :name="getFieldLanguage(language)"
            :validation-state="validationState[getFieldLanguage(language)]"
            @change="onChange($event, language)"
            @input="onInput($event, language)"
          />
        </hit-tab>
      </hit-tabs>
      <hit-input-text-area
        v-else
        v-model:value="value[getFieldLanguage(sortedLanguages[0])]"
        :rows="rows"
        :inline-input="true"
        inline-label-width="1fr"
        :name="getFieldLanguage(sortedLanguages[0])"
        :validation-state="
          validationState[getFieldLanguage(sortedLanguages[0])]
        "
        @change="onChange($event, sortedLanguages[0])"
        @input="onInput($event, sortedLanguages[0])"
      />
    </div>
    <div v-else-if="type === 'richtext'">
      <hit-tabs
        v-if="sortedLanguages.length > 1"
        @tabSelect="onTabSelected"
      >
        <hit-tab
          v-for="language in sortedLanguages"
          :id="language.id"
          :key="language.id"
          :title="language.longDesignation"
          :visible="language.id === visibleLanguage"
        >
          <hit-input-rich-text
            v-model:value="value[getFieldLanguage(language)]"
            :inline-input="true"
            inline-label-width="1fr"
            :name="getFieldLanguage(language)"
            :validation-state="validationState[getFieldLanguage(language)]"
            @change="onChange($event, language)"
            @input="onInput($event, language)"
          />
        </hit-tab>
      </hit-tabs>
      <hit-input-rich-text
        v-else
        v-model:value="value[getFieldLanguage(sortedLanguages[0])]"
        :inline-input="true"
        inline-label-width="1fr"
        :name="getFieldLanguage(sortedLanguages[0])"
        :validation-state="
          validationState[getFieldLanguage(sortedLanguages[0])]
        "
        @change="onChange($event, sortedLanguages[0])"
        @input="onInput($event, sortedLanguages[0])"
      />
    </div>
    <template #help>
      <!-- @slot Help of the input -->
      <slot name="help" />
    </template>
  </hit-base-input>
</template>

<script>
import {
  HitBaseInput,
  HitFormValidationMixin,
  HitInput,
  HitInputMixin,
  HitTabs,
} from '@hit/components';
import _ from 'lodash';
import {useI18n} from 'vue-i18n';
import HitButton from '@hit/components/src/components/button/HitButton.vue';
import {parseLocale, useConfigurationStore} from '@hit/base';

export default {
  inject: ['tHitField'],
  name: 'HitAppInputMultiLanguages',
  components: {HitButton, HitBaseInput, HitInput, HitTabs},
  mixins: [HitInputMixin, HitFormValidationMixin],
  setup() {
    const {t} = useI18n();
    const configStore = useConfigurationStore();
    return {t, configStore};
  },
  props: {
    /**
     * Type of the inputs to display per language
     */
    type: {
      type: String,
      default: 'text',
      validator: function (value) {
        // The value must match one of these strings
        return ['text', 'textarea', 'richtext'].indexOf(value) !== -1;
      },
    },
    /**
     * Field that is localized
     */
    field: {
      type: String,
      required: true,
    },
    /**
     * Value of the input
     */
    value: {
      type: Object,
      required: true,
    },
    /**
     * Number of rows to display for the textarea
     */
    rows: {
      type: Number,
      required: false,
      default: null,
    },
    instantFocus: {
      type: Boolean,
      default: false,
    },
    instantTouch: {
      type: Boolean,
      default: false,
    },
    allLanguagesVisibleDefault: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allLanguagesVisible: this.allLanguagesVisibleDefault,
      selectedLanguageTab: null,
    };
  },
  computed: {
    fieldValidationState() {
      if (this.validationState && this.validationState.$validationGroups) {
        //TODO migr test if works, Nigel 20230302 i cant manage to execute this line in order to test if ti works.
        return this.validationState.$validationGroups[this.field];
      } else {
        return {};
      }
    },
    displayTranslationsTooltip() {
      if (this.allLanguagesVisible) {
        return this.t('hit-base.common.hide-translations');
      } else {
        return this.t('hit-base.common.show-translations');
      }
    },
    locales() {
      return this.configStore.companyLanguagesSnakeCase;
    },
    displayedLanguages() {
      if (!this.allLanguagesVisible) {
        return [this.sortedLanguages[0]];
      } else {
        return this.sortedLanguages;
      }
    },
    sortedLanguages() {
      return this.locales.map((language) => parseLocale(language));
    },
    defaultLocale() {
      return this.sortedLanguages[0].id;
    },
    visibleLanguage() {
      if (this.selectedLanguageTab !== null) {
        return this.selectedLanguageTab;
      } else {
        return this.defaultLocale;
      }
    },
  },
  methods: {
    getFieldLanguage(language) {
      return this.tHitField(this.field, {valueSuffix: language.idApp});
    },
    onInput: _.debounce(function (value, language) {
      let valueCopy = Object.assign({}, this.value);
      valueCopy[this.getFieldLanguage(language)] = value;
      this.fireInputInput(valueCopy);
      if (this.validationState[this.getFieldLanguage(language)]) {
        this.validationState[this.getFieldLanguage(language)].$touch();
      }
    }, 100),
    onChange(value, language) {
      let valueCopy = Object.assign({}, this.value);
      valueCopy[this.getFieldLanguage(language)] = value;
      this.fireInputChange(valueCopy);
    },
    onTabSelected($event) {
      this.selectedLanguageTab = $event;
    },
    getInputValidation(language) {
      if (this.validationState) {
        let fieldNameWithSuffix = this.getFieldLanguage(language);
        return this.validationState[fieldNameWithSuffix];
      } else {
        return null;
      }
    },
  },
  mounted() {
    if (this.instantTouch) {
      for (let language of this.displayedLanguages) {
        let touchFn = this.validationState[this.getFieldLanguage(language)]
          .$touch;
        touchFn();
        // for this rare ocurrence of a minor problem, setTimeout is good enough for a solution
        setTimeout(touchFn, 1000);
      }
    }
  },
};
</script>

<style lang="scss">
.hit-i18n-input .hit-form-row {
  .hit-form-row-label {
    @apply text-left;
  }
}
</style>
