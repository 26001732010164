export default class EnumerationValue {
  constructor(id, key, t) {
    this.id = id;
    this.key = key;
    this.label = t(key);
  }

  get fullDesignation() {
    return this.label;
  }
}
