export default {
  props: {
    workInProgress: Boolean,
    validations: Object,
    itemPropertiesToDisplay: Array,
    tabLinesToDisplay: Array,
    itemsSelectable: Boolean,
    autoSave: Boolean,
    /**
     * Unique item identifier property (must be unique)
     */
    itemIdProperty: {
      type: String,
      default: 'id',
    },
  },
  computed: {
    mustShowCheckBoxes() {
      return this.itemsSelectable && !this.workInProgress;
    },
  },
  methods: {
    getItemIdentifier(item) {
      return item[this.itemIdProperty];
    },
  },
  inject: {
    getValueType: {
      default: undefined,
    },
    getValueDataObjectCLass: {
      default: undefined,
    },
    getLineClass: {
      default: undefined,
    },
    editItem: {
      default: undefined,
    },
    selectItem: {
      default: undefined,
    },
    unselectItem: {
      default: undefined,
    },
    generateCustomSlotName: {
      default: undefined,
    },
    openInNewTab: {
      default: undefined,
    },
  },
};
