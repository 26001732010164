export default class ColourUtils {
  /**
   * Default colour of the application
   */
  static defaultColour = '#00997c';

  /**
   * Calculates the luminance of the bg and returns the font colour to use to have
   * the best user experience
   */
  static getFontColorOnBg(bgColor, bgOpacity = 1) {
    bgColor = bgColor.replace('#', '');
    const r = parseInt(bgColor.substring(0, 2), 16);
    const g = parseInt(bgColor.substring(2, 4), 16);
    const b = parseInt(bgColor.substring(4, 6), 16);
    const baseR = 255,
      baseG = 255,
      baseB = 255;
    const blendedR = Math.round(r * bgOpacity + baseR * (1 - bgOpacity));
    const blendedG = Math.round(g * bgOpacity + baseG * (1 - bgOpacity));
    const blendedB = Math.round(b * bgOpacity + baseB * (1 - bgOpacity));
    const luminance =
      (0.2126 * blendedR + 0.7152 * blendedG + 0.0722 * blendedB) / 255;
    return luminance > 0.65 ? 'var(--text-tag-dark)' : 'var(--text-tag-light)';
  }

  /**
   * Extracts the hexadecimal value of the tailwind colour from the actual theme
   */
  static getTailwindColour(className, hashTagIncluded = true) {
    const darkTheme = document.querySelector('.dark-theme');
    let value = '';
    if (darkTheme) {
      const computedStyle = getComputedStyle(darkTheme);
      value = computedStyle.getPropertyValue(className).trim();
    } else {
      const rootStyles = getComputedStyle(document.documentElement);
      value = rootStyles.getPropertyValue(className).trim();
    }
    if (hashTagIncluded) return value;
    return value.replaceAll('#', '');
  }
}
