import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {MultiLanguageDesignationModel} from './index';
import BaseModel from './BaseModel';

export default class DueDate extends MultiLanguageDesignationModel {
  constructor({
    id,
    number,
    designationFrBe = undefined,
    designationDeBe = undefined,
    designationNlBe = undefined,
    designationFrLu = undefined,
    designationDeLu = undefined,
    designationFrFr = undefined,
    designationDeDe = undefined,
    designationNlNl = undefined,
    designationEnGb = undefined,
    designationEnIn = undefined,
    designationMrIn = undefined,
    designationHiIn = undefined,
    days = 0,
    endOfMonth = false,
  }) {
    super({
      id,
      number,
      designationFrBe,
      designationDeBe,
      designationNlBe,
      designationFrLu,
      designationDeLu,
      designationFrFr,
      designationDeDe,
      designationNlNl,
      designationEnGb,
      designationEnIn,
      designationMrIn,
      designationHiIn,
    });
    this.days = days;
    this.endOfMonth = endOfMonth;
  }

  static get config() {
    const config = BaseModel.config;
    config['addable'] = false;
    return config;
  }
  get config() {
    return DueDate.config;
  }

  static get fkName() {
    return 'due_date_id';
  }
  static get apiRoute() {
    return 'due_date';
  }
  static get detailPageRoute() {
    return 'dueDateList';
  }
  static get listPageRoute() {
    return 'dueDateList';
  }
  get fkName() {
    return DueDate.fkName;
  }
  get apiRoute() {
    return DueDate.apiRoute;
  }
  get listPageRoute() {
    return DueDate.listPageRoute;
  }
  get detailPageRoute() {
    return DueDate.detailPageRoute;
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      'days',
      'endOfMonth',
    ]);
  }

  static get joinAttrsKey() {
    return [
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      'days',
      'endOfMonth',
    ];
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      ...this.generateMultiLanguageField({
        key: 'designation',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.designation',
      }),
      days: new HitContainerAttribute(ATTRIBUTE_TYPES.INT, {
        tableProperties: {
          header: 'hit-app.common.days',
          maxWidth: '1fr',
        },
      }),
      endOfMonth: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'end_of_month',
        tableProperties: {
          header: 'hit-app.common.end_of_month',
          maxWidth: '1fr',
        },
      }),
    };
  }
}
