<template>
  <div v-if="!inlineInput">
    <OnClickOutside
      @trigger="
        () => {
          $emit('clickOutside');
          hideDropdown();
        }
      "
    >
      <div class="hit-dropdown relative w-full">
        <hit-button
          v-if="!$slots.replaceButton"
          :ref="(ref) => (parentButton = ref)"
          :label="hideLabel ? null : label"
          :prefix-icon="icon"
          :suffix-icon="hideDropdownIcon || !label ? null : 'expand-bottom'"
          :color="buttonColor"
          :custom-bg-color="customBgColor"
          :icon-color-style="iconColorStyle"
          :white-background="whiteBackground"
          :remove-outline="true"
          :remove-padding="removePadding"
          :hide-border="hideBorder"
          :gap-size="buttonGapSize"
          :icon-size="iconSize"
          :prevent-click="true"
          :class="{
            'h-6': removePadding,
          }"
          :disabled="disabled"
          @click="toggleDropdown($event)"
        >
          <template #prefixIcon>
            <!-- @slot Prefix of the button -->
            <slot name="prefix" />
          </template>
        </hit-button>
        <slot
          name="replaceButton"
          :toggle-dropdown="toggleDropdown"
        />
        <div
          :class="{
            absolute: responsiveBreakpointSm || !fullWidthUnderSm,
            'fixed w-full p-2': !responsiveBreakpointSm && fullWidthUnderSm,
            'max-w-auto': dropdownVisible,
            'max-w-0': removeDropdownContainer,
            'origin-top-left left-0': fixOnLeftSide(),
            'origin-top-right right-0': !fixOnLeftSide(),
            'bottom-full': !fixOnTop(),
            'top-full': fixOnTop(),
          }"
          class="fixed mt-2 w-auto rounded-md whitespace-no-wrap z-50"
        >
          <div
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
            :class="{
              'dropdown-visible': dropdownVisible || forceDropDownVisible,
              'transition-on-open': fixOnTop(),
              'transition-on-open-from-bottom': !fixOnTop(),
            }"
            class="rounded-md bg-panel ring-1 ring-black ring-opacity-5 text-panel-light py-1 dropdown-content"
          >
            <!-- @slot Content of the dropdown menu -->
            <slot :toggle-dropdown-fn="toggleDropdown" />
          </div>
        </div>
        <slot
          name="suffix"
          :toggle-dropdown-fn="toggleDropdown"
        />
      </div>
    </OnClickOutside>
  </div>
  <div v-else>
    <hit-button
      v-if="!$slots.replaceButton"
      :id="'rootDom' + uuid"
      :label="hideLabel ? null : label"
      :prefix-icon="icon"
      :suffix-icon="hideDropdownIcon || !label ? null : 'expand-bottom'"
      :color="buttonColor"
      :custom-bg-color="customBgColor"
      :icon-color-style="iconColorStyle"
      :white-background="whiteBackground"
      :remove-outline="true"
      :remove-padding="removePadding"
      :hide-border="hideBorder"
      :gap-size="buttonGapSize"
      :icon-size="iconSize"
      :prevent-click="true"
      :class="{
        'h-6': removePadding,
      }"
      :disabled="disabled"
      @click="toggleDropdown($event)"
    >
      <template #prefixIcon>
        <!-- @slot Prefix of the button -->
        <slot name="prefix" />
      </template>
    </hit-button>
    <Teleport to="#root-element">
      <OnClickOutside @trigger="dropdownVisible">
        <div
          class="absolute rounded-md bg-panel ring-1 ring-black ring-opacity-5 text-panel-light py-1 dropdown-content transition-on-open w-40 z-40"
          :style="dropdownStyle"
          :class="{
            'dropdown-visible': dropdownVisible,
          }"
        >
          <slot :toggle-dropdown-fn="toggleDropdown" />
        </div>
      </OnClickOutside>
    </Teleport>
  </div>
</template>

<script>
import {HitButton} from '../button';
import {OnClickOutside} from '@vueuse/components';
import HitBreakpointsMixin from '@hit/components/src/mixins/breakpoints/HitBreakpointsMixin';
import {HitUUIDUtils} from '../../utils';

export default {
  name: 'HitDropdown',
  components: {
    OnClickOutside,
    HitButton,
  },
  mixins: [HitBreakpointsMixin],
  provide() {
    return {
      hideDropdown: this.hideDropdown,
    };
  },
  props: {
    /**
     * Label of the dropdown button to display
     */
    label: {
      type: String,
      default: null,
    },
    /**
     * Icon of the dropdown button
     */
    icon: {
      type: String,
      default: null,
    },
    /**
     * Specifies the colors to use for the button
     */
    buttonColor: String,
    /**
     * Forces the background to be white
     */
    whiteBackground: Boolean,
    /**
     * Hide the dropdown button label
     */
    hideLabel: Boolean,
    /**
     * Hide the dropdown button icon
     */
    hideDropdownIcon: Boolean,
    /**
     * Hide the dropdown button border
     */
    hideBorder: Boolean,
    /**
     * Remove the dropdown button padding
     */
    removePadding: Boolean,
    /**
     * Size of gap in the dropdown button
     */
    buttonGapSize: {
      type: String,
      default: 'normal',
      validator(value) {
        return ['none', 'small', 'normal'].indexOf(value) !== -1;
      },
    },
    /**
     * Size of the icon in the dropdown button
     */
    iconSize: {
      type: String,
      default: 'base',
      validator: function (value) {
        // The value must match one of these strings
        return (
          ['2xs', 'xs', 'sm', 'base', 'md', 'lg', 'xl'].indexOf(value) !== -1
        );
      },
    },
    /**
     * Can be used to force the visible state of the dropdown from the parent element
     */
    forceDropDownVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullWidthUnderSm: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconColorStyle: {
      type: Object,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    inlineInput: {
      type: Boolean,
      required: false,
      default: null,
    },
    customBgColor: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      parentButton: undefined,
      dropdownVisible: false,
      uuid: HitUUIDUtils.generate(),
      rect: undefined,
      removeDropdownContainer: true,
    };
  },
  computed: {
    dropdownStyle() {
      let result = {};
      if (!this.rect) return result;
      const spaceRight = window.innerWidth - this.rect.right;
      result.top = this.rect.bottom + 5 + 'px';
      if (this.rect.left > spaceRight) {
        result.right = window.innerWidth - this.rect.right + 'px';
      } else {
        result.left = this.rect.left + 'px';
      }
      return result;
    },
  },
  methods: {
    fixOnLeftSide() {
      try {
        const rect = this.parentButton.$el.getBoundingClientRect();
        return window.innerWidth - rect.left > rect.right;
      } catch {
        return true;
      }
    },
    fixOnTop() {
      try {
        const rect = this.parentButton.$el.getBoundingClientRect();
        return !(rect.top > window.innerHeight - rect.bottom);
      } catch {
        return true;
      }
    },
    toggleDropdown(evt = undefined) {
      this.removeDropdownContainer = false;
      this.dropdownVisible = !this.dropdownVisible;
      if (!this.dropdownVisible) {
        setTimeout(() => {
          this.removeDropdownContainer = true;
        }, 350);
      }
      this.$emit('click');
      this.dropdownVisible ? this.$emit('open') : this.$emit('close');
      if (evt) {
        evt.stopImmediatePropagation();
      }
    },
    hideDropdown() {
      if (this.dropdownVisible) {
        this.toggleDropdown();
      }
      this.$emit('outsideClick');
    },
    updatePosition() {
      try {
        const rootDom = document.getElementById(`rootDom${this.uuid}`);
        this.rect = rootDom.getBoundingClientRect();
      } catch (e) {
        this.rect = undefined;
      }
    },
  },
  watch: {
    dropdownVisible(newValue) {
      if (this.inlineInput) {
        this.updatePosition();
        const canvasDom = document.getElementById('content-body-container');
        if (newValue) {
          canvasDom.addEventListener('scroll', this.updatePosition);
        } else {
          canvasDom.removeEventListener('scroll', this.updatePosition);
        }
      }
    },
  },
};
</script>

<style scoped>
.transition-on-open {
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: top;
}

.transition-on-open-from-bottom {
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: bottom;
}

.dropdown-content.dropdown-visible {
  max-height: 500px;
  opacity: 1;
  transform: scaleY(1);
  @apply shadow-lg;
}
</style>
