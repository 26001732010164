<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-due-date')"
    route="due_date"
    auto-complete-id="due_date"
    :table-attributes="tableAttributes"
    :default-sort="{property: 'number', order: 'asc'}"
    :filter-id="filterId"
    :filter-id-column="filterIdColumn"
    :only-load-active-records="onlyLoadActiveRecords"
    @item-selected="selectItem"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {useConfigurationStore} from '../../../store';
import {DueDate} from '../../../models';
export default {
  name: 'HitDueDateBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    filterId: {
      type: String,
      required: false,
    },
    filterIdColumn: {
      type: String,
      required: false,
    },
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    const configStore = useConfigurationStore();
    return {t, configStore};
  },
  data() {
    return {
      tableAttributes: DueDate.browserDisplayAttrs,
      listDataConfig: {},
    };
  },
  methods: {
    selectItem(data) {
      const dueDate = new DueDate(data);
      this.$emit('itemSelected', dueDate);
    },
  },
  emits: ['itemSelected'],
};
</script>
