<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-transaction')"
    route="transaction"
    auto-complete-id="transaction"
    :table-attributes="tableAttributes"
    :data-list-config="dataListConfig"
    :default-sort="{property: 'designation', order: 'desc'}"
    :only-load-active-records="false"
    @item-selected="selectItem"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {Transaction} from '../../../models';
import {HitUtils} from '@hit/components/src/utils/hit/HitUtils';

export default {
  name: 'HitTransactionBrowser',
  components: {
    HitEntityBrowser,
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tableAttributes: Transaction.browserDisplayAttrs,
      dataListConfig: {
        zone1CustomFunction: (item) =>
          `${item?.journal?.number} - ${item.documentNumber}`,
        zone2Field: 'designation',
        zone3CustomFunction: (item) => HitUtils.fullLocationString(item),
      },
    };
  },
  methods: {
    selectItem(data) {
      const transaction = new Transaction(data);
      this.$emit('itemSelected', transaction);
    },
  },
  emits: ['itemSelected'],
};
</script>
