<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-unit')"
    route="unit"
    auto-complete-id="unit"
    :table-attributes="tableAttributes"
    :default-sort="{property: 'number', order: 'asc'}"
    :only-load-active-records="onlyLoadActiveRecords"
    :data-list-config="dataListConfig"
    @item-selected="selectItem"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {useConfigurationStore} from '../../../store';
import {Unit} from '../../../models';

export default {
  name: 'HitUnitBrowser',
  components: {
    HitEntityBrowser,
  },
  setup() {
    const {t} = useI18n();
    const config = useConfigurationStore();
    return {t, config};
  },
  props: {
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      tableAttributes: Unit.browserDisplayAttrs,
      dataListConfig: {
        zone1Field: `unit${this.config.companyLanguagesPascalCase}`,
        zone2Field: `designation${this.config.companyLanguagesPascalCase}`,
        zone3Field: 'peppolId',
      },
    };
  },
  methods: {
    selectItem(data) {
      const unit = new Unit(data);
      this.$emit('itemSelected', unit);
    },
  },
  emits: ['itemSelected'],
};
</script>
