// This will be the parent class for the common data of transaction_item, purchase_item, project_part_item, etc.

import {BaseModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';

export default class Item extends BaseModel {
  constructor({
    id,
    number,
    designation,
    description = undefined,
    parent = undefined,
    active = true,
    option = undefined,
    itemType = undefined,
    titleLevel = undefined,
    resource = undefined,
    adminType = undefined,
    quantity = undefined,
    unit = undefined,
    discount = undefined,
    taxCode = undefined,
    comment = undefined,
    calculated = false,
  }) {
    super(id, number, designation);
    this.description = description;
    this.parent = parent;
    this.active = active;
    this.option = option;
    this.itemType = itemType;
    this.titleLevel = titleLevel;
    this.resource = resource;
    this.adminType = adminType;
    this.quantity = quantity;
    this.unit = unit;
    this.discount = discount;
    this.taxCode = taxCode;
    this.comment = comment;
    this.calculated = calculated;
  }

  /**
   * We can only pass the attributes that are present for all the three tables
   */
  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '0.75fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-base.common.designation',
          maxWidth: '1.5fr',
        },
      }),
      description: new HitContainerAttribute(ATTRIBUTE_TYPES.RICH_TEXT, {
        tableProperties: {
          header: 'hit-base.common.description',
          maxWidth: '1fr',
        },
      }),
      quantity: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 3,
        decimalDefaultValue: 0,
        tableProperties: {
          header: 'hit-app.common.quantity',
        },
      }),
      unit: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_UNIT, {
        column: 'unit_id',
        joinPath: undefined, // Set in child class
        tableProperties: {
          header: 'hit-base.common.unit',
          maxWidth: '0.5fr',
        },
      }),
      taxCode: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_TAX_CODE, {
        column: 'tax_code_id',
        joinPath: undefined, // Set in child class
        tableProperties: {
          header: 'hit-app.common.invoice-tax-code',
        },
      }),
      itemType: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'item_type',
        tableProperties: {
          header: 'hit-app.transaction.item-type',
        },
      }),
      titleLevel: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'title_level',
        tableProperties: {
          header: 'hit-app.transaction.title-level',
        },
      }),
      resource: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_RESOURCE, {
        column: 'resource_id',
        joinPath: undefined, // Set in child class
        tableProperties: {
          header: 'hit-base.common.resource',
        },
      }),
      comment: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-base.common.comment',
          maxWidth: '1fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-app.common.active',
          maxWidth: '3.5rem',
        },
      }),
      option: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-app.common.option',
          maxWidth: '3.5rem',
        },
      }),
      calculated: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'quantity_calculated',
        tableProperties: {
          header: 'calculated',
        },
      }),
      adminType: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'admin_type',
        tableProperties: {
          header: 'hit-app.transaction.admin-type',
          maxWidth: '0.5fr',
        },
      }),
      discount: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalDefaultValue: 1,
        decimalPrecision: 4,
        tableProperties: {
          header: 'hit-app.common.discount',
          maxWidth: '1fr',
        },
      }),
    };
  }
}
