<template>
  <hit-input-checkbox
    :label="labelToDisplay"
    :value="container.data[attribute]"
    :disabled="container.attributes[attribute].readOnly"
    @change="(value) => container.handleChange(attribute, value)"
  />
</template>
<script>
import {HitInputCheckbox} from '@hit/components';
import {useI18n} from 'vue-i18n';
export default {
  name: 'HitContainerInputCheckbox',
  components: {
    HitInputCheckbox,
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    /**
     * Overrides the header prop of the container attribute if we want to display
     * another label then the standard one
     */
    label: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Set this flag to hide the label in case of a group input where the label
     * of the first input needs to be passed to the group input component
     */
    hideLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  computed: {
    /**
     * To define the label we need to display, we have the following options (taken in this order)
     * 1. Label prop passed to this input
     * 2. Header value of the tableProperties config from the attribute
     * 3. Empty string is displayed
     */
    labelToDisplay() {
      if (this.hideLabel) return null;
      if (this.label) return this.label;
      const att = this.container.attributes[this.attribute];
      const trans = att?.tableProperties?.header ?? '';
      return this.t(trans);
    },
  },
};
</script>
