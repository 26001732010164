// @ts-nocheck
import axios from 'axios';
import { globals } from '../../../../app/src/globals';
import { translate } from '../../plugins/i18n/i18n';
import { useNotificationsStore } from '../../store';
export class AxiosSingleton {
    static _instance;
    axios;
    constructor() {
        this.axios = axios.create();
        this.axios.interceptors.request.use(async (config) => {
            const token_is_valid = globals.$keycloak.token && !globals.$keycloak.isTokenExpired();
            if (token_is_valid) {
                config.headers['Authorization'] = `Bearer ${globals.$keycloak.token}`;
            }
            else {
                console.error(`Error : Session expired`);
                const msg = globals.$i18n.global.t('hit-app.keycloak.session-expired-please-login');
                if (confirm(msg)) {
                    globals.$keycloak.logout();
                }
                throw new this.axios.Cancel(msg);
            }
            return config;
        }, (error) => {
            // Gérer les erreurs de l'intercepteur de requête ici
            return Promise.reject(error);
        });
    }
    static get Instance() {
        return this._instance || (this._instance = new this());
    }
}
export class DataService {
    static get baseUrl() {
        return process.env.VUE_APP_POSTGREST_URL;
    }
    static get axios() {
        return AxiosSingleton.Instance.axios;
    }
    /**
     * Returns promise of the api call
     * @param route route of the model, with a slash at the beginning
     * @param attributes fields to query
     * @param filters dict with key value pairs where key is the field name and value is the filter to apply,
     * see <a href="https://postgrest.org/en/stable/api.html?highlight=attributes#horizontal-filtering-rows">Documentation</a>
     * @param order for example 'name.asc' or 'name.desc'
     * @param range for example '23-68'
     * @param getCount request to get the total number of items in the table from Postgrest
     * @param limit maximum number of elements, only used when range is omitted or invalid
     * @param offset offset from which the first element gets taken
     * @param config request configuration, see <a href="https://axios-http.com/docs/req_config">Documentation</a>
     */
    static read(route, { attributes = '', filters = {}, order = '', range = '', getCount = false, limit = 0, offset = 0, config = {}, }) {
        const path = this.baseUrl + route;
        //TODO nigelapi implement/test correct escaping
        const params = {};
        if (attributes)
            params['select'] = attributes;
        if (order)
            params['order'] = order;
        if (limit !== 0)
            params['limit'] = limit;
        if (offset !== 0)
            params['offset'] = offset;
        if (range) {
            const rangeArray = range.split('-');
            const rangeLower = parseInt(rangeArray[0], 10);
            const rangeUpper = parseInt(rangeArray[1], 10);
            if (rangeArray.length < 2 || isNaN(rangeLower) || isNaN(rangeUpper)) {
                //TODO nigel check error handling, silent or visible?
                console.error('Invalid range');
                // throw new Error("Invalid range")
            }
            else {
                params['limit'] = rangeUpper - rangeLower;
                params['offset'] = rangeLower;
            }
        }
        if (Object.keys(filters).length > 0) {
            Object.keys(filters).forEach((key) => {
                params[key] = filters[key];
            });
        }
        config.params = params;
        if (!config.headers) {
            config.headers = {};
        }
        if (getCount) {
            config.headers['Prefer'] = 'count=exact'; //TODO check if count=planned should be used instead
        }
        config.headers['Authorization'] = 'Bearer ' + globals.$keycloak.token;
        config.headers['Accept-Profile'] = globals.$keycloak.tokenParsed.schema_id;
        return this.axios.get(path, config);
    }
    //TODO nigelapi what to return?
    /**
     * Returns
     * @param route route of the model, with a slash at the beginning
     * @param input Object(s) to insert, dict for single object, list of dicts for multiple objects, must contain key value pairs for every column to fill
     * @param config request configuration, see <a href="https://axios-http.com/docs/req_config">Documentation</a>
     */
    static create(route, input, config = {}) {
        const path = this.baseUrl + route;
        if (!config.headers) {
            config.headers = {};
        }
        config.headers['Authorization'] = 'Bearer ' + globals.$keycloak.token;
        config.headers['Content-Profile'] = globals.$keycloak.tokenParsed.schema_id;
        return this.axios.post(path, input, config);
    }
    /**
     *
     * @param route route of the model, with a slash at the beginning
     * @param filters dict with key value pairs where key is the field name and value is the filter to apply,
     * see <a href="https://postgrest.org/en/stable/api.html?highlight=attributes#horizontal-filtering-rows">Documentation</a>
     * @param values key value pairs for every column to fill
     * @param config request configuration, see <a href="https://axios-http.com/docs/req_config">Documentation</a>
     */
    static update(route, filters, values, config = {}) {
        const path = this.baseUrl + route;
        config.params = filters;
        if (!config.headers) {
            config.headers = {};
        }
        config.headers['Authorization'] = 'Bearer ' + globals.$keycloak.token;
        config.headers['Content-Profile'] = globals.$keycloak.tokenParsed.schema_id;
        return this.axios.patch(path, values, config);
    }
    /**
     *
     * @param route route of the model, with a slash at the beginning
     * @param filters dict with key value pairs where key is the field name and value is the filter to apply,
     * see <a href="https://postgrest.org/en/stable/api.html?highlight=attributes#horizontal-filtering-rows">Documentation</a>
     * @param config request configuration, see <a href="https://axios-http.com/docs/req_config">Documentation</a>
     */
    static delete(route, filters, config = {}) {
        const path = this.baseUrl + route;
        config.params = filters;
        if (!config.headers) {
            config.headers = {};
        }
        config.headers['Authorization'] = 'Bearer ' + globals.$keycloak.token;
        config.headers['Content-Profile'] = globals.$keycloak.tokenParsed.schema_id;
        return this.axios
            .delete(path, config)
            .then((response) => {
            return response;
        })
            .catch((error) => {
            if (error.response.status === 409) {
                useNotificationsStore().insertBannerNotification(translate('hit-base.common.error-409'));
                throw Error('Dataservice delete => 409');
            }
            else {
                console.error(error);
                throw Error('An error happened during the delete call of the Dataservice');
            }
        });
    }
    static procedure(route, data, { attributes = '', filters = {}, order = '', range = '', getCount = false, limit = 0, offset = 0, config = {}, } = {}) {
        const path = this.baseUrl + 'rpc/' + route;
        if (!config.headers) {
            config.headers = {};
        }
        config.headers['Authorization'] = 'Bearer ' + globals.$keycloak.token;
        config.headers['Content-Profile'] = globals.$keycloak.tokenParsed.schema_id;
        const params = {};
        if (attributes)
            params['select'] = attributes;
        if (order)
            params['order'] = order;
        if (limit !== 0)
            params['limit'] = limit;
        if (offset !== 0)
            params['offset'] = offset;
        config.params = params;
        if (getCount) {
            config.headers['Prefer'] = 'count=exact'; //TODO check if count=planned should be used instead
        }
        return this.axios.post(path, data, config);
    }
    //TODO nigelapi what to return?
    /**
     * Returns
     * @param route route of the model, with a slash at the beginning
     * @param input Object(s) to insert, dict for single object, list of dicts for multiple objects, must contain key value pairs for every column to fill
     */
    static upsert(route, input) {
        return this.create(route, input, {
            headers: { prefer: 'resolution=merge-duplicates' },
        });
    }
    /**
     * needed to escape strings in postgrest filters that contain the symbols .,:
     */
    static escape(str) {
        return '%22' + str + '%22';
    }
    static resetLinkedDocuments(fkName, items) {
        return DataService.update('document', { [fkName]: `in.(${items.map((i) => i.id).join(',')})` }, { [fkName]: null });
    }
}
