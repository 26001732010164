import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {
  Address,
  MultiLanguageDesignationModel,
  ResourceCategory,
  Unit,
} from './index';
import MultiLanguageField from './MultiLanguageField';
import BaseModel from './BaseModel';

export default class Resource extends MultiLanguageDesignationModel {
  constructor({
    id,
    number,
    designationFrBe = undefined,
    designationDeBe = undefined,
    designationNlBe = undefined,
    designationFrLu = undefined,
    designationDeLu = undefined,
    designationFrFr = undefined,
    designationDeDe = undefined,
    designationNlNl = undefined,
    designationEnGb = undefined,
    designationEnIn = undefined,
    designationMrIn = undefined,
    designationHiIn = undefined,
    descriptionFrBe = undefined,
    descriptionDeBe = undefined,
    descriptionNlBe = undefined,
    descriptionFrLu = undefined,
    descriptionDeLu = undefined,
    descriptionFrFr = undefined,
    descriptionDeDe = undefined,
    descriptionNlNl = undefined,
    descriptionEnGb = undefined,
    descriptionEnIn = undefined,
    descriptionMrIn = undefined,
    descriptionHiIn = undefined,
    customisable = false,
    unit = undefined,
    resourceCategory = undefined,
    keywords = undefined,
    weight = undefined,
    volume = undefined,
    defaultSupplier = undefined,
    composite = undefined,
    unfold = undefined,
    purchasePrice = undefined,
    baseMarginApplied = false,
    baseMargin = undefined,
    basePrice = undefined,
    salesMarginApplied = false,
    salesMargin = undefined,
    salesPrice = undefined,
    active = false,
    memo = undefined,

    // We also need to add the key how it is stored in the db (we use the db column names)
    no = undefined,
    designation = undefined,
  }) {
    super({
      id,
      number: number || no,
      designationFrBe,
      designationDeBe,
      designationNlBe,
      designationFrLu,
      designationDeLu,
      designationFrFr,
      designationDeDe,
      designationNlNl,
      designationEnGb,
      designationEnIn,
      designationMrIn,
      designationHiIn,
    });
    this.descriptions = new MultiLanguageField({
      descriptionFrBe,
      descriptionDeBe,
      descriptionNlBe,
      descriptionFrLu,
      descriptionDeLu,
      descriptionFrFr,
      descriptionDeDe,
      descriptionNlNl,
      descriptionEnGb,
      descriptionEnIn,
      descriptionMrIn,
      descriptionHiIn,
    });
    this.description = this.descriptions.value;
    this.customisable = customisable;
    this.unit = unit ? new Unit(unit) : null;
    this.resourceCategory = resourceCategory
      ? new ResourceCategory(resourceCategory)
      : null;
    this.keywords = keywords;
    this.weight = weight;
    this.volume = volume;
    this.defaultSupplier = defaultSupplier
      ? new Address(defaultSupplier)
      : null;
    this.composite = composite;
    this.unfold = unfold;
    this.purchasePrice = purchasePrice;
    this.baseMarginApplied = baseMarginApplied;
    this.baseMargin = baseMargin;
    this.basePrice = basePrice;
    this.salesMarginApplied = salesMarginApplied;
    this.salesMargin = salesMargin;
    this.salesPrice = salesPrice;
    this.active = active;
    this.memo = memo;

    // For form items where we only store the designation in the data
    if (designation) {
      this.designation = designation;
    }
  }

  static get fkName() {
    return 'resource_id';
  }
  static get apiRoute() {
    return 'resource';
  }
  static get listPageRoute() {
    return 'resourceList';
  }
  static get detailPageRoute() {
    return 'resourceDetail';
  }
  get fkName() {
    return Resource.fkName;
  }
  get apiRoute() {
    return Resource.apiRoute;
  }
  get detailPageRoute() {
    return Resource.detailPageRoute;
  }
  get listPageRoute() {
    return Resource.listPageRoute;
  }

  static get attrsToStoreInForm() {
    return [
      'number',
      this.attributeCompanyLocale('designation'),
      this.attributeUserLocale('description'),
      'basePrice',
      'unit',
    ];
  }
  get attrsToStoreInForm() {
    return BaseModel.attrsToStoreInForm;
  }

  static get joinAttrsKey() {
    return [
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      this.attributeUserLocale('description'),
      this.attributeCompanyLocale('description'),
      'basePrice',
      'salesPrice',
      'unit',
      'customisable',
    ];
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      'unit',
      'resourceCategory',
      'purchasePrice',
      'salesPrice',
      'customisable',
      'composite',
      'active',
      'tags',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes(
      [
        'number',
        this.attributeUserLocale('designation'),
        this.attributeCompanyLocale('designation'),
        'unit',
        'resourceCategory',
        'basePrice',
        'salesPrice',
        'customisable',
        'composite',
        'tags',
      ],
      [
        this.attributeUserLocale('description'),
        this.attributeCompanyLocale('description'),
        'purchasePrice',
      ]
    );
  }

  static get createInitColumns() {
    return {
      base_margin_applied: true,
      sales_margin_applied: true,
      base_margin: 1.0,
      sales_margin: 1.0,
      purchase_price: 0.0,
      base_price: 0.0,
      sales_price: 0.0,
    };
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      ...this.generateMultiLanguageField({
        key: 'designation',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.designation',
      }),
      ...this.generateMultiLanguageField({
        key: 'description',
        type: ATTRIBUTE_TYPES.RICH_TEXT,
        header: 'hit-base.common.description',
      }),
      customisable: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        default: false,
        tableProperties: {
          header: 'hit-app.resource.generic',
          sharable: true,
          maxWidth: '1fr',
        },
      }),
      unit: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_UNIT, {
        joinPath: ['unit!fk_resource_unit_id_unit'],
        tableProperties: {
          header: 'hit-base.common.unit',
        },
      }),
      resourceCategory: new HitContainerAttribute(
        ATTRIBUTE_TYPES.FK_RESOURCE_CATEGORY,
        {
          joinPath: ['fk_resource_resource_category_id_resource_category'],
          tableProperties: {
            header: 'hit-base.common.resource-category',
          },
        }
      ),
      keywords: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'keywords',
        tableProperties: {
          header: 'hit-app.resource.keywords',
          sharable: true,
          maxWidth: '0.55fr',
        },
      }),
      weight: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'weight',
        tableProperties: {
          header: 'hit-app.resource.weight',
          sharable: true,
          maxWidth: '0.5fr',
        },
      }),
      volume: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'volume',
        tableProperties: {
          header: 'hit-app.resource.volume',
          sharable: true,
          maxWidth: '0.5fr',
        },
      }),
      defaultSupplier: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_ADDRESS, {
        fkToUpdate: 'default_supplier_id',
        joinPath: ['address!fk_resource_default_supplier_id_address'],
        column: 'default_supplier_id',
        tableProperties: {
          header: 'hit-app.common.default-supplier',
        },
      }),
      composite: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'composite',
          header: 'hit-app.resource.composite',
          maxWidth: '1fr',
        },
      }),
      unfold: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-app.resource.unfold',
        },
      }),
      purchasePrice: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'purchase_price',
        decimalPrecision: 2,
        decimalDefaultValue: 0,
        tableProperties: {
          header: 'hit-app.common.purchase-price',
          maxWidth: '1fr',
        },
      }),
      baseMarginApplied: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'base_margin_applied',
        tableProperties: {
          header: 'hit-app.common.base-margin',
        },
      }),
      baseMargin: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'base_margin',
        decimalPrecision: 4,
        decimalDefaultValue: 1,
        tableProperties: {
          header: 'hit-app.common.base-margin',
        },
      }),
      basePrice: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'base_price',
        decimalPrecision: 2,
        decimalDefaultValue: 0,
        tableProperties: {
          header: 'hit-app.common.base-price',
          maxWidth: '1fr',
        },
      }),
      salesMarginApplied: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        column: 'sales_margin_applied',
        tableProperties: {
          header: 'hit-app.common.sales-margin',
        },
      }),
      salesMargin: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'sales_margin',
        decimalPrecision: 4,
        decimalDefaultValue: 1,
        tableProperties: {
          header: 'hit-app.common.sales-margin',
        },
      }),
      salesPrice: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'sales_price',
        decimalPrecision: 2,
        decimalDefaultValue: 0,
        tableProperties: {
          header: 'hit-app.common.sales-price',
          maxWidth: '1fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'active',
          header: 'hit-app.common.active',
          maxWidth: '1fr',
        },
      }),
      tags: new HitContainerAttribute(ATTRIBUTE_TYPES.TAGS, {
        tableProperties: {
          header: 'hit-base.common.tags',
          maxWidth: '1fr',
        },
      }),
      memo: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-base.common.memo',
        },
      }),
    };
  }
}
