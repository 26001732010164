import {BaseModel, Resource} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';

export default class ResourcePackaging extends BaseModel {
  constructor({id, resource, factor}) {
    super(id, undefined, undefined);
    this.resource = resource ? new Resource(resource) : null;
    this.factor = factor;
  }

  static get fkName() {
    return null;
  }
  static get apiRoute() {
    return 'resource_packaging';
  }
  static get listPageRoute() {
    return undefined;
  }
  static get detailPageRoute() {
    return undefined;
  }
  static get createInitColumns() {
    return {};
  }
  get fkName() {
    return ResourcePackaging.fkName;
  }
  get apiRoute() {
    return ResourcePackaging.apiRoute;
  }
  get detailPageRoute() {
    return ResourcePackaging.detailPageRoute;
  }
  get listPageRoute() {
    return ResourcePackaging.listPageRoute;
  }
  get createInitColumns() {
    return ResourcePackaging.createInitColumns;
  }

  static get entityListDisplayAttrs() {
    return this.getAttributes(['unit', 'factor']);
  }

  static get allAttributes() {
    return {
      unit: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_UNIT, {
        joinPath: ['unit!fk_resource_packaging_unit_id_unit'],
        tableProperties: {
          header: 'hit-base.common.unit',
        },
      }),
      resource: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_RESOURCE, {
        column: 'resource_id',
        joinPath: ['resource!fk_resource_packaging_resource_id_resource'],
        fkToUpdate: 'data_resource_id',
        tableProperties: {
          header: 'hit-base.common.resource',
        },
      }),
      factor: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 4,
        decimalDefaultValue: 1,
        column: 'factor',
        tableProperties: {
          header: 'hit-app.common.factor',
          maxWidth: '1fr',
        },
      }),
    };
  }
}
