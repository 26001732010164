import {v4 as uuidv4} from 'uuid';

export default class {
  static generate() {
    return uuidv4();
  }

  // Probability that an uuid has exactly this value = 1 to 10^37
  static noResponseUuid() {
    return '00000000-0000-0000-0000-000000000000';
  }
}
