<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-journal')"
    route="journal"
    :table-attributes="tableAttributes"
    :default-sort="{property: 'number', order: 'asc'}"
    :filter-id="filterId"
    :filter-id-column="filterIdColumn"
    :only-load-active-records="onlyLoadActiveRecords"
    :custom-filters="customFilters"
    @item-selected="selectItem"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {Journal} from '../../../models';
export default {
  name: 'HitJournalBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    filterId: {
      type: String,
      required: false,
    },
    filterIdColumn: {
      type: String,
      required: false,
    },
    customFilters: {
      type: Object,
      required: false,
      default: null,
    },
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tableAttributes: Journal.browserDisplayAttrs,
      dataListConfig: {
        zone3CustomFunction: (item) =>
          `${item?.journalType?.label} - ${item?.transactionType?.label}`,
      },
    };
  },
  methods: {
    selectItem(data) {
      const journal = new Journal(data);
      this.$emit('itemSelected', journal);
    },
  },
  emits: ['itemSelected'],
};
</script>
