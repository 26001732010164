import BaseModel from '../BaseModel';
import {Staff} from '../index';
import MultiLanguageField from '../MultiLanguageField';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../../components';

export default class Form extends BaseModel {
  constructor({
    id,
    designation,
    author = undefined,
    finished = false,
    status = undefined,
    submissionDate = undefined,
    validationDate = undefined,
    injectionDate = undefined,
    formDefinitionDesignationFrBe = undefined,
    formDefinitionDesignationDeBe = undefined,
    formDefinitionDesignationNlBe = undefined,
    formDefinitionDesignationFrLu = undefined,
    formDefinitionDesignationDeLu = undefined,
    formDefinitionDesignationFrFr = undefined,
    formDefinitionDesignationDeDe = undefined,
    formDefinitionDesignationNlNl = undefined,
    formDefinitionDesignationEnGb = undefined,
    formDefinitionDesignationEnIn = undefined,
    formDefinitionDesignationMrIn = undefined,
    formDefinitionDesignationHiIn = undefined,
  }) {
    super(id, '', designation);
    this.author = author ? new Staff(author) : null;
    this.finished = finished;
    this.status = status;
    this.submissionDate = submissionDate;
    this.validationDate = validationDate;
    this.injectionDate = injectionDate;
    this.formDefinitionDesignations = new MultiLanguageField({
      formDefinitionDesignationFrBe,
      formDefinitionDesignationDeBe,
      formDefinitionDesignationNlBe,
      formDefinitionDesignationFrLu,
      formDefinitionDesignationDeLu,
      formDefinitionDesignationFrFr,
      formDefinitionDesignationDeDe,
      formDefinitionDesignationNlNl,
      formDefinitionDesignationEnGb,
      formDefinitionDesignationEnIn,
      formDefinitionDesignationMrIn,
      formDefinitionDesignationHiIn,
    });
    this.formDefinitionDesignation = this.formDefinitionDesignations.value;
  }

  static get fkName() {
    return 'form_id';
  }
  static get apiRoute() {
    return 'form_view';
  }
  static get detailPageRoute() {
    return 'formItem';
  }
  static get listPageRoute() {
    return 'formItem';
  }
  get fkName() {
    return Form.fkName;
  }
  get apiRoute() {
    return Form.apiRoute;
  }
  get listPageRoute() {
    return Form.listPageRoute;
  }
  get detailPageRoute() {
    return Form.detailPageRoute;
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'designation',
      this.attributeUserLocale('formDefinitionDesignation'),
      this.attributeCompanyLocale('formDefinitionDesignation'),
      'author',
      'status',
    ]);
  }

  static get joinAttrsKey() {
    return [
      'designation',
      this.attributeUserLocale('formDefinitionDesignation'),
      this.attributeCompanyLocale('formDefinitionDesignation'),
      'author',
      'status',
    ];
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'author',
      this.attributeUserLocale('formDefinitionDesignation'),
      this.attributeCompanyLocale('formDefinitionDesignation'),
      'linkedEntity',
      'designation',
      'formDate',
    ]);
  }

  static get allAttributes() {
    return {
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.designation',
          maxWidth: '2fr',
        },
      }),
      ...this.generateMultiLanguageField({
        key: 'formDefinitionDesignation',
        column: 'form_definition_designation',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.designation',
      }),
      linkedEntity: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'linked_entity',
        tableProperties: {
          header: 'hit-base.form.linked-entity',
          mainSearch: true,
        },
      }),
      author: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_STAFF, {
        column: 'author_id',
        joinPath: ['staff!fk_form_author_id_staff'],
        tableProperties: {
          header: 'hit-base.common.author',
        },
      }),
      finished: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN),
      status: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        tableProperties: {
          header: 'hit-app.common.status',
        },
      }),
      submissionDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'submission_date',
        tableProperties: {
          header: 'hit-app.form.submission-date',
        },
      }),
      validationDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'validation_date',
        tableProperties: {
          header: 'hit-app.form.validation-date',
        },
      }),
      injectionDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'injection_date',
        tableProperties: {
          header: 'hit-app.form.injection-date',
        },
      }),
      formDate: new HitContainerAttribute(ATTRIBUTE_TYPES.DATE, {
        column: 'form_date',
        tableProperties: {
          header: 'hit-app.form.form-date',
        },
      }),
    };
  }

  static factory() {
    return new Form({});
  }

  static getMTORelations() {
    return {
      formDefinition: {
        childIdColumn: 'form_definition_id',
        childTableName: 'form_definition',
      },
      author: {
        childIdColumn: 'author_id',
        childTableName: 'staff',
      },
    };
  }

  getStatusTextKey() {
    switch (this.status) {
      case 0:
        return 'hit-base.common.in-progress';
      case 1:
        return 'hit-base.common.sent';
      case 2:
        return 'hit-base.common.validated';
      case 3:
        return 'hit-base.common.injected';
      default:
        return 'hit-base.common.unknown';
    }
  }
}
