<template>
  <hit-entity-browser
    :title="t('hit-base.common.browser-title-project-part')"
    route="project_part"
    auto-complete-id="project-part"
    :table-attributes="tableAttributes"
    :default-sort="{property: 'number', order: 'asc'}"
    :filter-id="filterId"
    :filter-id-column="filterIdColumn"
    :only-load-active-records="onlyLoadActiveRecords"
    :data-list-config="listConfig"
    @item-selected="selectItem"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import {ProjectPart} from '../../../models';
export default {
  name: 'HitProjectPartBrowser',
  components: {
    HitEntityBrowser,
  },
  props: {
    filterId: {
      type: String,
      required: false,
    },
    filterIdColumn: {
      type: String,
      required: false,
    },
    onlyLoadActiveRecords: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  data() {
    return {
      tableAttributes: ProjectPart.browserDisplayAttrs,
      listConfig: {
        zone1CustomFunction: (item) => item?.project?.entityDesignation,
        zone3CustomFunction: (item) => item?.status?.label,
      },
    };
  },
  methods: {
    selectItem(data) {
      const projectPart = new ProjectPart(data);
      this.$emit('itemSelected', projectPart);
    },
  },
  emits: ['itemSelected'],
};
</script>
