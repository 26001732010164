import {
  MinValueConstraint,
  MaxValueConstraint,
  MaxLengthConstraint,
  MinLengthConstraint,
  MinListLengthConstraint,
  MaxListLengthConstraint,
  RequiredConstraint,
  MultipleValuesConstraint,
  IntegerConstraint,
} from '@hit/components';

class oldBaseModel {
  static factory() {
    throw Error(
      `Factory method should be implemented in each subclass of BaseHitModel: ${this.name}`
    );
  }
  static fromJson(json) {
    let result = this.factory();
    json = this.$transformJson(json);
    result = Object.assign(result, json);
    return result;
  }

  static $transformJson(json) {
    Object.keys(json).forEach((property) => {
      // Change dates to Date type instead of String
      if (property.toUpperCase().endsWith('DATE')) {
        json[property] = new Date(json[property]);
      }
    });

    return json;
  }

  /**
   *
   * @returns returns a dictionary of Many To Many relationships of the model
   */
  static getMTMRelations() {
    return {};
  }

  /**
   *
   * @returns returns a dictionary of Many To One relationships of the model
   */
  static getMTORelations() {
    return {};
  }

  static getIdColumn() {
    return 'id';
  }
}

const DEFAULT_FORM_DEFINITION =
  'version: 1\n' +
  'meta:\n' +
  '  name: \n' +
  '  validation:\n' +
  '    type: validate\n' +
  'sections: \n' +
  '  section1: \n' +
  '    meta:\n' +
  '      label: Section 1\n' +
  '    content:\n' +
  '      text: \n' +
  '        type: text\n' +
  '        label: Text\n' +
  '        constraints:\n' +
  '          required: true\n';

const FormDefinitionMetaContentFields = ['parent', 'formDate'];

class FormDefinition extends oldBaseModel {
  constructor({id, name, definition, active = true}) {
    super();
    this.id = id;
    this.name = name;
    // this.description = description;
    // this.entityType = entityType;
    // this.version = version;
    // this.schema = sections; // TODO rename schema to sections
    if (definition) {
      this.definition = new FormDefinitionData(definition);
    } else {
      this.definition = null;
    }
    this.active = active;
  }

  static factory() {
    return new FormDefinition({active: true});
  }
}

class MultilingualText {
  constructor(values) {
    this.values = values;
  }
  toJson() {
    if (this.values instanceof Object) {
      return {...this.values};
    } else {
      return this.values;
    }
  }
  getValue() {
    if (this.values instanceof Object) {
      if (this.values.fr_be) {
        return this.values.fr_be;
      } else {
        return this.values[0];
      }
    }
    return this.values;
  }
}

class FormDefinitionMeta {
  constructor({name, help, parent, formDate}) {
    this.name = new MultilingualText(name);
    this.help = new MultilingualText(help);
    this.parent = InputDefinitionFactory('parent', parent);
    this.formDate = InputDefinitionFactory('formDate', formDate);
  }
}

class FormDefinitionData {
  constructor({meta, sections}) {
    this.meta = new FormDefinitionMeta(meta);
    this.sections = [];
    if (sections == undefined) {
      sections = {};
    }
    for (const sectionId of Object.keys(sections)) {
      this.sections.push(
        new FormSectionDefinition(sectionId, sections[sectionId])
      );
    }
    this.sections.sort(function (a, b) {
      return a.meta.order - b.meta.order;
    });
  }
}

class FormSectionDefinitionMeta {
  constructor({order, label, help}) {
    this.order = order;
    this.label = new MultilingualText(label);
    this.help = new MultilingualText(help);
  }
  toJson() {
    return {
      order: this.order,
      type: this.type,
      label: this.label.toJson(),
      help: this.help.toJson(),
    };
  }
}

class FormSectionDefinition {
  constructor(id, {meta, content}) {
    this.id = id;
    this.meta = new FormSectionDefinitionMeta(meta);
    this.content = [];
    // this.importAllowed = false; // allow import data from previous form (with same entity, reporter, ...)
    for (const contentId of Object.keys(content)) {
      this.content.push(InputDefinitionFactory(contentId, content[contentId]));
    }
    this.content.sort(function (a, b) {
      return a.order - b.order;
    });
  }
}

function InputDefinitionFactory(id, options) {
  const result = null;
  if (options == undefined || options == null || options == {}) {
    return result;
  }
  switch (options.type) {
    case 'entity':
      return new EntityInputDefinition(id, options);
    case 'date':
      return new DateInputDefinition(id, options);
    case 'string':
      return new StringInputDefinition(id, options);
    case 'number':
      return new NumberInputDefinition(id, options);
    case 'checkbox':
      return new CheckboxInputDefinition(id, options);
    case 'radio':
      return new RadioButtonInputDefinition(id, options);
    case 'select':
      return new SelectInputDefinition(id, options);
    case 'richtext':
      return new RichTextInputDefinition(id, options);
    case 'textarea':
      return new LongTextInputDefinition(id, options);
    case 'int':
      return new NumberInputDefinition(id, options, true);
    case 'list':
      return new ListInputDefinition(id, options);
    default:
      return null;
  }
}

class BaseInputDefinition {
  constructor(
    id,
    label,
    order,
    help = undefined,
    constraints = [],
    defaultValue = null
  ) {
    this.id = id;
    this.label = new MultilingualText(label);
    this.help = new MultilingualText(help);
    this.order = order;
    this.constraints = constraints;
    this.default = defaultValue;
  }

  toJson() {
    let jsonConstraints = {};
    this.constraints.forEach((constraint) => {
      jsonConstraints[constraint.getJsonAttribute()] = constraint.toJson();
    });
    return {
      label: this.label.toJson(),
      help: this.help.toJson(),
      order: this.order,
      default: this.default,
      type: this.type,
      constraints: jsonConstraints,
      values: this.values,
      rows: this.rows,
    };
  }

  initValue() {
    return this.default;
  }

  getValidator() {
    let validator = {};
    this.constraints.forEach((contraint) =>
      contraint.addToValidator(validator)
    );
    return validator;
  }
}

class EntityInputDefinition extends BaseInputDefinition {
  constructor(
    id,
    {label, order, type, reference, tagList, help = undefined, constraints = {}}
  ) {
    let constraintList = [];
    //TODO Julien : voir comment gérer les constraints partagées
    if (constraints.required != undefined) {
      constraintList.push(new RequiredConstraint(constraints.required));
    }
    super(id, label, order, help, constraintList, null);
    this.type = type;
    this.reference = reference;
    this.attributeFilter = {
      tag: tagList,
    };
  }
  toJson() {
    let result = super.toJson();
    result['reference'] = this.reference;
    result['tagList'] = this.attributeFilter?.tagList ?? [];
    return result;
  }
}

class StringInputDefinition extends BaseInputDefinition {
  constructor(
    id,
    {label, order, help = undefined, constraints = {}, defaultValue = null}
  ) {
    let constraintList = [];
    if (constraints.required != undefined) {
      constraintList.push(new RequiredConstraint(constraints.required));
    }
    if (constraints.minLength != undefined) {
      constraintList.push(new MinLengthConstraint(constraints.minLength));
    }
    if (constraints.maxLength != undefined) {
      constraintList.push(new MaxLengthConstraint(constraints.maxLength));
    }
    super(id, label, order, help, constraintList, defaultValue);
    this.type = 'string';
  }
}
class NumberInputDefinition extends BaseInputDefinition {
  constructor(
    id,
    {label, order, help = undefined, constraints = {}, defaultValue = null},
    integer = false
  ) {
    let constraintList = [];
    if (constraints.required != undefined) {
      constraintList.push(new RequiredConstraint(constraints.required));
    }
    if (constraints.minValue != undefined) {
      constraintList.push(new MinValueConstraint(constraints.minValue));
    }
    if (constraints.maxValue != undefined) {
      constraintList.push(new MaxValueConstraint(constraints.maxValue));
    }
    if (integer) {
      constraintList.push(new IntegerConstraint(integer));
    }
    super(id, label, order, help, constraintList, defaultValue);
    this.type = 'number';
  }
}

class DateInputDefinition extends BaseInputDefinition {
  constructor(
    id,
    {label, order, help = undefined, constraints = {}, defaultValue = null}
  ) {
    let constraintList = [];
    if (constraints.required != undefined) {
      constraintList.push(new RequiredConstraint(constraints.required));
    }
    super(id, label, order, help, constraintList, defaultValue);
    this.type = 'date';
  }
}

class ListInputDefinition extends BaseInputDefinition {
  constructor(id, {label, order, content, help = undefined, constraints = {}}) {
    let constraintList = [];
    if (constraints.required != undefined) {
      constraintList.push(new RequiredConstraint(constraints.required));
    }
    if (constraints.minLength != undefined) {
      constraintList.push(new MinListLengthConstraint(constraints.minLength));
    }
    if (constraints.maxLength != undefined) {
      constraintList.push(new MaxListLengthConstraint(constraints.maxLength));
    }
    super(id, label, order, help, constraintList, []);
    this.content = content;
  }
}

class CheckboxInputDefinition extends BaseInputDefinition {
  constructor(
    id,
    {
      label,
      order,
      help = undefined,
      constraints = {},
      defaultValue = [],
      values = [],
    }
  ) {
    let constraintList = [];
    if (constraints.required != undefined) {
      constraintList.push(new RequiredConstraint(constraints.required));
    }

    super(id, label, order, help, constraintList, defaultValue);
    this.type = 'checkbox';
    this.values = values;
  }
}

class RadioButtonInputDefinition extends BaseInputDefinition {
  constructor(
    id,
    {
      label,
      order,
      help = undefined,
      constraints = {},
      defaultValue = null,
      values = [],
    }
  ) {
    let constraintList = [];
    if (constraints.required != undefined) {
      constraintList.push(new RequiredConstraint(constraints.required));
    }

    super(id, label, order, help, constraintList, defaultValue);
    this.type = 'radio';
    this.values = values;
  }
}

class SelectInputDefinition extends BaseInputDefinition {
  constructor(
    id,
    {
      label,
      order,
      help = undefined,
      constraints = {},
      defaultValue = null,
      values = [],
    }
  ) {
    let constraintList = [];
    if (constraints.required != undefined) {
      constraintList.push(new RequiredConstraint(constraints.required));
    }
    if (constraints.multiple != undefined) {
      constraintList.push(new MultipleValuesConstraint(constraints.multiple));
    }

    super(id, label, order, help, constraintList, defaultValue);
    this.type = 'select';
    this.values = values;
  }
}

class RichTextInputDefinition extends BaseInputDefinition {
  constructor(id, {label, order, help = undefined, constraints = {}}) {
    let constraintList = [];
    if (constraints.required != undefined) {
      constraintList.push(new RequiredConstraint(constraints.required));
    }
    if (constraints.minLength != undefined) {
      constraintList.push(new MinLengthConstraint(constraints.minLength));
    }
    if (constraints.maxLength != undefined) {
      constraintList.push(new MaxLengthConstraint(constraints.maxLength));
    }

    super(id, label, order, help, constraintList, null);
    this.type = 'richtext';
  }
}

class LongTextInputDefinition extends BaseInputDefinition {
  constructor(
    id,
    {label, order, help = undefined, rows = 3, constraints = {}}
  ) {
    let constraintList = [];
    if (constraints.required != undefined) {
      constraintList.push(new RequiredConstraint(constraints.required));
    }
    if (constraints.minLength != undefined) {
      constraintList.push(new MinLengthConstraint(constraints.minLength));
    }
    if (constraints.maxLength != undefined) {
      constraintList.push(new MaxLengthConstraint(constraints.maxLength));
    }

    super(id, label, order, help, constraintList, null);
    this.type = 'textarea';
    this.rows = rows;
  }
}

class StaffInputDefinition extends BaseInputDefinition {
  constructor(id, {label, order, help = undefined, constraints = {}}) {
    let constraintList = [];
    if (constraints.required != undefined) {
      constraintList.push(new RequiredConstraint(constraints.required));
    }
    super(label, order, help, constraintList);
  }
}

export {
  FormDefinition,
  FormDefinitionMetaContentFields,
  FormSectionDefinition,
  StringInputDefinition,
  ListInputDefinition,
  CheckboxInputDefinition,
  RadioButtonInputDefinition,
  SelectInputDefinition,
  RichTextInputDefinition,
  LongTextInputDefinition,
  StaffInputDefinition,
  MultilingualText,
  DEFAULT_FORM_DEFINITION,
};
