import MultiLanguageDesignationModel from './MultiLanguageDesignationModel';
import MultiLanguageField from './MultiLanguageField';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import BaseModel from './BaseModel';

export default class StandardText extends MultiLanguageDesignationModel {
  constructor({
    id,
    number,
    designationFrBe = undefined,
    designationDeBe = undefined,
    designationNlBe = undefined,
    designationFrLu = undefined,
    designationDeLu = undefined,
    designationFrFr = undefined,
    designationDeDe = undefined,
    designationNlNl = undefined,
    designationEnGb = undefined,
    designationEnIn = undefined,
    designationMrIn = undefined,
    designationHiIn = undefined,
    descriptionFrBe = undefined,
    descriptionDeBe = undefined,
    descriptionNlBe = undefined,
    descriptionFrLu = undefined,
    descriptionDeLu = undefined,
    descriptionFrFr = undefined,
    descriptionDeDe = undefined,
    descriptionNlNl = undefined,
    descriptionEnGb = undefined,
    descriptionEnIn = undefined,
    descriptionMrIn = undefined,
    descriptionHiIn = undefined,
    active = false,
  }) {
    super({
      id,
      number,
      designationFrBe,
      designationDeBe,
      designationNlBe,
      designationFrLu,
      designationDeLu,
      designationFrFr,
      designationDeDe,
      designationNlNl,
      designationEnGb,
      designationEnIn,
      designationMrIn,
      designationHiIn,
    });
    this.descriptions = new MultiLanguageField({
      descriptionFrBe,
      descriptionDeBe,
      descriptionNlBe,
      descriptionFrLu,
      descriptionDeLu,
      descriptionFrFr,
      descriptionDeDe,
      descriptionNlNl,
      descriptionEnGb,
      descriptionEnIn,
      descriptionMrIn,
      descriptionHiIn,
    });
    this.description = this.descriptions.value;
    this.active = active;
  }

  static get config() {
    const config = BaseModel.config;
    config['browsable'] = false;
    return config;
  }
  get config() {
    return StandardText.config;
  }

  static get fkName() {
    return null;
  }
  static get apiRoute() {
    return 'standard_text';
  }
  static get listPageRoute() {
    return 'standardTextList';
  }
  static get detailPageRoute() {
    return 'standardTextDetail';
  }
  static get createInitColumns() {
    return {};
  }
  get fkName() {
    return StandardText.fkName;
  }
  get apiRoute() {
    return StandardText.apiRoute;
  }
  get detailPageRoute() {
    return StandardText.detailPageRoute;
  }
  get listPageRoute() {
    return StandardText.listPageRoute;
  }
  get createInitColumns() {
    return StandardText.createInitColumns;
  }

  static get joinAttrsKey() {
    return [
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      this.attributeUserLocale('description'),
      this.attributeCompanyLocale('description'),
    ];
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      'active',
    ]);
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      ...this.generateMultiLanguageField({
        key: 'designation',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.designation',
      }),
      ...this.generateMultiLanguageField({
        key: 'description',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.description',
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          header: 'hit-app.common.active',
          maxWidth: '0.5fr',
        },
      }),
    };
  }
}
