<template>
  <hit-select
    :label="t('hit-app.printer-configuration.select-language')"
    :label-fields="['name']"
    :full-width="true"
    :options="printLanguages"
    :value="value"
    :row-label="false"
    :clearable="false"
    @change="onChange"
  />
</template>

<script>
import {useI18n} from 'vue-i18n';
import {HitInputMixin, HitSelect} from '@hit/components';
import {useConfigurationStore} from '@hit/base';
import {supportedLanguages} from 'hit-online-web-ui/src/plugins/i18n';

export default {
  name: 'HitAppInputPrintLanguage',
  components: {HitSelect},
  mixins: [HitInputMixin],
  props: {
    /**
     * Selected value of the template select
     */
    value: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Label of the template select
     */
    label: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      printLanguages: [],
    };
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  async beforeMount() {
    // Limit the selectable languages to the languages supported by the company and set the default language to the main language of the company
    useConfigurationStore().companyLanguagesSnakeCase.forEach((locale) => {
      this.printLanguages.push({
        name: this.t(`hit-base.common.${locale}-long`),
        id: locale,
      });
    });
    this.fireInputChange(this.printLanguages[0].id);
  },
  methods: {
    onChange(value) {
      this.fireInputChange(value);
    },
  },
};
</script>

<style scoped lang="scss"></style>
