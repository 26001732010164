import {BaseModel, MultiLanguageDesignationModel} from './index';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';

export default class ResourceCategory extends MultiLanguageDesignationModel {
  constructor({
    id,
    number,
    designationFrBe = undefined,
    designationDeBe = undefined,
    designationNlBe = undefined,
    designationFrLu = undefined,
    designationDeLu = undefined,
    designationFrFr = undefined,
    designationDeDe = undefined,
    designationNlNl = undefined,
    designationEnGb = undefined,
    designationEnIn = undefined,
    designationMrIn = undefined,
    designationHiIn = undefined,
    margin = undefined,
  }) {
    super({
      id,
      number,
      designationFrBe,
      designationDeBe,
      designationNlBe,
      designationFrLu,
      designationDeLu,
      designationFrFr,
      designationDeDe,
      designationNlNl,
      designationEnGb,
      designationEnIn,
      designationMrIn,
      designationHiIn,
    });
    this.margin = margin;
  }

  static get fkName() {
    return 'resource_category_id';
  }
  static get apiRoute() {
    return 'resource_category';
  }
  static get listPageRoute() {
    return 'resourceCategoryList';
  }
  static get detailPageRoute() {
    return 'resourceCategoryList';
  }
  static get createInitColumns() {
    return {};
  }
  static get config() {
    const config = BaseModel.config;
    config['addable'] = false;
    return config;
  }
  get fkName() {
    return ResourceCategory.fkName;
  }
  get apiRoute() {
    return ResourceCategory.apiRoute;
  }
  get detailPageRoute() {
    return ResourceCategory.detailPageRoute;
  }
  get listPageRoute() {
    return ResourceCategory.listPageRoute;
  }
  get config() {
    return ResourceCategory.config;
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      'margin',
    ]);
  }

  static get joinAttrsKey() {
    return [
      'number',
      this.attributeUserLocale('designation'),
      this.attributeCompanyLocale('designation'),
      'margin',
    ];
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      ...this.generateMultiLanguageField({
        key: 'designation',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-base.common.designation',
      }),
      margin: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        decimalPrecision: 4,
        decimalDefaultValue: 1,
        tableProperties: {
          header: 'hit-app.common.margin',
          maxWidth: '0.5fr',
        },
      }),
    };
  }
}
