<template>
  <hit-input-hours
    :label="labelToDisplay"
    :value="container.data[attribute]"
    :steps="steps"
    @changeTime="handleChangeTime"
  />
</template>
<script>
import {HitInputHours} from '@hit/components';
import {nextTick} from 'vue';
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitContainerInputHours',
  components: {
    HitInputHours,
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attribute: {
      type: String,
      required: true,
    },
    steps: {
      type: Number,
      required: false,
      default: 0,
    },
    /**
     * Overrides the header prop of the container attribute if we want to display
     * another label then the standard one
     */
    label: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * Set this flag to hide the label in case of a group input where the label
     * of the first input needs to be passed to the group input component
     */
    hideLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    /**
     * To define the label we need to display, we have the following options (taken in this order)
     * 1. Label prop passed to this input
     * 2. Header value of the tableProperties config from the attribute
     * 3. Empty string is displayed
     */
    labelToDisplay() {
      if (this.hideLabel) return null;
      if (this.label) return this.label;
      const att = this.container.attributes[this.attribute];
      const trans = att?.tableProperties?.header ?? '';
      return this.t(trans);
    },
  },
  methods: {
    handleChangeTime(value) {
      // needed to force reactivity, when for example value is 0 and user enters invalid input, the input value doesn't
      // get replaced by 0 otherwise
      if (this.container.data[this.attribute] === value) {
        this.container.data[this.attribute] = -1;
        let handleChangeFunction = () =>
          this.container.handleChange(this.attribute, value);
        nextTick(() => {
          handleChangeFunction();
        });
      } else {
        this.container.handleChange(this.attribute, value);
      }
    },
  },
};
</script>
