import {schema} from 'normalizr';
import {tagSchema} from '../common';

const staffSchema = new schema.Entity('staff', {
  tags: [tagSchema],
});


const formDefinitionSchema = new schema.Entity('form/formDefinition', {});
const formSchema = new schema.Entity('form/form', {
  formDefinition: formDefinitionSchema,
  author: staffSchema,
  validator: staffSchema,
  injector: staffSchema,
});

const formTemplateSchema = new schema.Entity('form/formTemplate', {
  author: staffSchema,
});

export {formDefinitionSchema, formSchema, formTemplateSchema};