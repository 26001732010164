<template>
  <hit-base-input
    :label="label"
    :validation-state="validationState"
    :inline-input="inlineInput"
    :use-custom-height="!singleValued || useCustomHeight"
    :full-width="true"
  >
    <div class="grid grid-cols-1">
      <div
        v-for="(item, itemId) in checkboxValues"
        :key="getItemId(item, itemId)"
        class="relative flex"
        :class="{
          'pt-2': !removePadding,
          'set-margin': inTable && inlineInput,
          'items-center': centerVertically,
          'items-start': !centerVertically,
        }"
      >
        <div class="flex items-center h-5">
          <input
            :id="getItemId(item, itemId)"
            :checked="isItemSelected(getItemId(item, itemId))"
            type="checkbox"
            class="hit-checkbox focus:border-input-focus focus:ring-transparent focus:outline-none"
            :class="{
              'cursor-not-allowed': disabled,
              'cursor-pointer': !disabled,
            }"
            :disabled="readonly || disabled"
            :indeterminate.prop="indeterminate"
            @change="onInputCheckboxChange"
            @click="onInputCheckboxClick"
          >
        </div>
        <hit-input-option
          v-if="displayOption"
          :id="getItemId(item, itemId)"
          :label="getLocalizedValue(item.label)"
          :help="getLocalizedValue(item.help)"
        />
      </div>
    </div>
    <template #help>
      <!-- @slot Help of the checkbox -->
      <slot name="help" />
    </template>
  </hit-base-input>
</template>

<script>
import HitBaseInput from './HitBaseInput';
import HitInputMixin from '../../../mixins/form/HitInputMixin';
import HitFormValidationMixin from '../../../mixins/form/HitFormValidationMixin';
import HitInputOption from './HitInputOption';

export default {
  name: 'HitInputCheckbox',
  components: {HitBaseInput, HitInputOption},
  mixins: [HitInputMixin, HitFormValidationMixin],
  props: {
    /**
     * Values of the checkbox
     */
    values: {
      type: [String, Array, Object],
      required: false,
      default: () => {
        return {
          default: {
            id: 'default',
          },
        };
      },
    },
    /**
     * Whether the checkbox is checked or list of checked values
     */
    value: {
      type: [Array, Boolean],
      required: false,
      default: false,
    },
    /**
     * Remove the checkbox padding
     */
    removePadding: {
      type: Boolean,
      default: true,
    },
    /**
     * Enable indeterminate state
     */
    indeterminate: Boolean,
    /**
     * Function to get the localized value of the label and help
     */
    getLocalizedValue: {
      type: Function,
      default: (item) => {
        return item;
      },
    },
    centerVertically: {
      type: Boolean,
      required: false,
      default: true,
    },
    displayOption: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      checkedValue: null,
    };
  },
  computed: {
    checkboxValues() {
      if (this.values instanceof Array || this.values instanceof Object) {
        return this.values;
      } else {
        return {
          default: {
            id: this.values,
            label: this.values,
          },
        };
      }
    },
    singleValued() {
      return Array.isArray(this.values)
        ? this.values.length === 1
        : this.values instanceof Object
        ? Object.keys(this.values).length === 1
        : true;
    },
    inTable() {
      return (
        this.$parent.$options.name === 'HitTableCell' ||
        this.$parent.$parent.$options.name === 'HitTableCell'
      );
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.checkedValue = value ? value : this.singleValued ? false : [];
      },
    },
  },
  methods: {
    onInputCheckboxChange($event) {
      if (this.validationState) {
        this.validationState.$touch();
      }
      if ($event.target.checked) {
        if (this.singleValued) {
          this.checkedValue = true;
        } else {
          this.checkedValue.push($event.target.id);
        }
      } else {
        if (this.singleValued) {
          this.checkedValue = false;
        } else {
          let index = this.checkedValue.indexOf($event.target.id);
          this.checkedValue.splice(index, 1);
        }
      }
      this.fireInputChange(this.checkedValue);
      $event.stopImmediatePropagation();
    },
    onInputCheckboxClick($event) {
      this.$emit('click', $event);
      $event.stopImmediatePropagation();
    },
    isItemSelected(itemId) {
      return Array.isArray(this.checkedValue)
        ? this.checkedValue.includes(itemId)
        : this.checkedValue;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
[type='checkbox'].hit-checkbox {
  @apply h-4 w-4 rounded border-input bg-input;
}

[type='checkbox'].hit-checkbox:checked:before {
  background-color: red;
}

[type='checkbox'].hit-checkbox:checked {
  @apply bg-checkbox-checked;
}

[type='checkbox'].hit-checkbox:focus {
  @apply ring-0 ring-offset-0;
}

[type='checkbox'].hit-checkbox:checked:focus {
  @apply bg-checkbox-checked;
}

[type='checkbox'].hit-checkbox:checked:hover {
  @apply bg-checkbox-checked;
}

.hit-checkbox:indeterminate {
  border: none;
  @apply bg-no-repeat bg-center bg-red-900;

  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='8' height='2' x='4' y='7' rx='1'/%3E%3C/svg%3E");
}

.set-margin {
  margin: 0 9px;
}
</style>
