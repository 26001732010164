import {makeEntityStore} from '../../../helpers/factory';
import {FormDefinition} from '../../../../models';
import {BaseRootEntityService} from '../../../../api';
import {formDefinitionSchema} from '../../../../models/hit/form/schema';

export default makeEntityStore({
  modelCls: FormDefinition,
  service: new BaseRootEntityService('form_definition', FormDefinition),
  schema: formDefinitionSchema,
});
