export const DEFAULT_ICON = '__default_icon__';
const MATERIAL_ICONS_MAPPING = {
  'empty-star': 'star_outline',
  'filled-star': 'star',
  apps: 'apps',
  user: 'groups',
  settings: 'tune',
  trash: 'delete_outline',
  warning: 'report_problem',
  info: 'info',
  search: 'search',
  clear: 'clear',
  'expand-bottom': 'expand_more',
  'expand-top': 'expand_less',
  'expand-left': 'chevron_left',
  'expand-right': 'chevron_right',
  error: 'error_outline',
  'event-upcoming': 'event_upcoming',
  success: 'check_circle_outline',
  home: 'home',
  'credit-card': 'credit_card',
  add: 'add',
  form: 'assignment',
  history: 'history',
  'form-definition': 'sticky_note_2',
  list: 'reorder',
  chat: 'chat',
  'option-menu': 'more_vert',
  minimize: 'remove',
  'restore-size': 'crop_7_5',
  'maximize-size': 'crop_din',
  sort: 'unfold_more',
  'sort-up': 'arrow_drop_up',
  'sort-down': 'arrow_drop_down',
  truck: 'local_shipping',
  printer: 'print',
  download: 'download',
  cloud_download: 'cloud_download',
  upload: 'cloud_upload',
  save: 'save',
  edit: 'edit',
  back: 'arrow_back',
  forward: 'arrow_forward',
  send: 'send',
  check: 'check',
  support: 'support_agent',
  disconnect: 'exit_to_app',
  phone: 'call',
  'mobile-phone': 'stay_current_portrait',
  email: 'email',
  'email-unread': 'mark_email_unread',
  help: 'help_outline',
  location: 'gps_fixed',
  refresh: 'refresh',
  globe: 'public',
  tags: 'label',
  company: 'business',
  filter: 'filter_alt',
  no_filter: 'filter_alt_off',
  calendar: 'calendar_today',
  project: 'corporate_fare',
  'company-switch': 'swap_horiz',
  book: 'menu_book',
  book2: 'book_2',
  flag: 'flag',
  box: 'box',
  video: 'video_library',
  share: 'share',
  'share-reviews': 'share_reviews',
  idea: 'lightbulb',
  'user-account': 'account_circle',
  policy: 'policy',
  faq: 'quiz',
  file: 'description',
  import: 'upload_file',
  contrast: 'contrast',
  undo: 'undo',
  language: 'translate',
  notification: 'notifications',
  task: 'task',
  duplicate: 'file_copy',
  person: 'person',
  'person-add': 'person_add',
  percent: 'percent',
  costs: 'finance',
  'drag-handle': 'drag_handle',
  'file-preview': 'open_in_new',
  preview: 'preview',
  copy: 'content_copy',
  signature: 'signature',
  menu: 'menu',
  role: 'badge',
  'radio-checked': 'radio_button_checked',
  format_bold: 'format_bold',
  format_italic: 'format_italic',
  format_underlined: 'format_underlined',
  format_align_left: 'format_align_left',
  format_align_right: 'format_align_right',
  format_align_center: 'format_align_center',
  format_align_justify: 'format_align_justify',
  format_list_bulleted: 'format_list_bulleted',
  format_list_numbered: 'format_list_numbered',
  format_color_fill: 'format_color_fill',
  format_color_text: 'format_color_text',
  format_h1: 'format_h1',
  format_h2: 'format_h2',
  format_h3: 'format_h3',
  format_h4: 'format_h4',
  format_h5: 'format_h5',
  format_h6: 'format_h6',
  annotate: 'edit_note',
  image: 'image',
  users: 'group',
  clock: 'schedule',
  'show-on': 'visibility',
  'show-off': 'visibility_off',
  calculate: 'calculate',
  arrow_upward: 'arrow_upward',
  arrow_downward: 'arrow_downward',
  arrow_back: 'arrow_back',
  arrow_forward: 'arrow_forward',
  arrow_left_right: 'sync_alt',
  'double-left': 'keyboard_double_arrow_left',
  'double-right': 'keyboard_double_arrow_right',
  insert_text: 'insert_text',
  shuffle: 'shuffle',
  two_columns: 'splitscreen_right',
  action: 'task_alt',
  'add-action': 'add_task',
  play: 'play_arrow',
  stop: 'stop',
  done: 'done',
  'card-info': 'more_horiz',
  'edit-note': 'edit_note',
  tag: 'sell',
  close: 'close',
  archive: 'archive',
  stack: 'list',
  dashboard: 'insert_chart',
  schema: 'schema',
  transaction: 'contract',
  pause: 'pause',
  'hours-icon': 'schedule',
  merge: 'merge',
  reopen: 'restart_alt',
  customize: 'dashboard_customize',
  advanced_settings: 'settings',
  light_theme: 'light_mode',
  dark_theme: 'dark_mode',
  create_folder: 'create_new_folder',
  list_add: 'playlist_add',
  import_lines: 'post_add',
  add_rounded: 'add_circle',
  go_to: 'open_in_new',
  shopping_cart: 'shopping_cart',
  storage: 'storage',
  lock: 'lock',
  description: 'quick_reference',
  key: 'key',
  action_status_closed: 'check_small',
  action_status_assigned: 'person',
  action_status_opened: 'assignment_add',
  navigation: 'near_me',
  stock: 'inventory_2',
  increase: 'trending_up',
  decrease: 'trending_down',
  compare_arrows: 'compare_arrows',
  elevation: 'elevation',
  order_approve: 'order_approve',
  peppol: 'diversity_2',
  today: 'today',
};

MATERIAL_ICONS_MAPPING[DEFAULT_ICON] = 'bug_report';

export const ICONS_MAPPING = {
  material: MATERIAL_ICONS_MAPPING,
};
