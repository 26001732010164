import {useConfigurationStore} from '../../store';

export default class MultiLanguageField {
  constructor(fields) {
    this.values = {};
    for (const [key, value] of Object.entries(fields)) {
      const pascalCaseKey = key
        .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert space before each uppercase letter
        .replace(/^(description|designation|unit|legal_clause)/, '') // Remove prefix
        .split(/[^a-zA-Z0-9]/) // Split on non-alphanumeric characters
        .filter(Boolean) // Remove empty strings
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        ) // Capitalize each word
        .join(''); // Join them back into a single string
      this.values[pascalCaseKey] = value;
    }
  }

  get value() {
    return (
      this.values[useConfigurationStore().userLanguagePascalCase] ||
      this.values[useConfigurationStore().mainLanguagePascalCase]
    );
  }
}
