import {HitFormService} from '../../../../api';
import {makeEntityStore} from '../../../helpers/factory';
import {Form} from '../../../../models';
import {formSchema} from '../../../../models/hit/form/schema';

export default makeEntityStore({
  modelCls: Form,
  service: new HitFormService(),
  schema: formSchema,
});
