import BaseModel from './BaseModel';
import {ATTRIBUTE_TYPES, HitContainerAttribute} from '../../components';
import {required} from '@vuelidate/validators';
import {HitOnlineEnums} from 'hit-online-web-ui/src/helpers/constants';
import {translate} from '../../plugins/i18n/i18n';
import {Country} from './index';
import MultiLanguageField from './MultiLanguageField';

const max3Characters = (data) => {
  if (data) {
    return data.length <= 3;
  } else {
    return true;
  }
};

export default class TaxCode extends BaseModel {
  constructor({
    id,
    number,
    designation,
    journalType = undefined,
    country = undefined,
    peppolId = undefined,
    active = true,
    percentage = undefined,
    legalClauseFrBe = undefined,
    legalClauseDeBe = undefined,
    legalClauseNlBe = undefined,
    legalClauseFrLu = undefined,
    legalClauseDeLu = undefined,
    legalClauseFrFr = undefined,
    legalClauseDeDe = undefined,
    legalClauseNlNl = undefined,
    legalClauseEnGb = undefined,
    legalClauseEnIn = undefined,
    legalClauseMrIn = undefined,
    legalClauseHiIn = undefined,
  }) {
    super(id, number, designation);
    this.journalType = journalType
      ? HitOnlineEnums.getEnumValue('JOURNAL_TYPE', journalType, translate)
      : null;
    this.country = country ? new Country(country) : undefined;
    this.peppolId = peppolId;
    this.active = active;
    this.percentage = percentage;
    this.legalClauses = new MultiLanguageField({
      legalClauseFrBe,
      legalClauseDeBe,
      legalClauseNlBe,
      legalClauseFrLu,
      legalClauseDeLu,
      legalClauseFrFr,
      legalClauseDeDe,
      legalClauseNlNl,
      legalClauseEnGb,
      legalClauseEnIn,
      legalClauseMrIn,
      legalClauseHiIn,
    });
    this.legalClause = this.legalClauses.value;
  }

  static get fkName() {
    return 'tax_code_id';
  }
  static get apiRoute() {
    return 'tax_code';
  }
  static get detailPageRoute() {
    return 'taxCodeDetail';
  }
  static get listPageRoute() {
    return 'taxCodeList';
  }
  get fkName() {
    return TaxCode.fkName;
  }
  get apiRoute() {
    return TaxCode.apiRoute;
  }
  get listPageRoute() {
    return TaxCode.listPageRoute;
  }
  get detailPageRoute() {
    return TaxCode.detailPageRoute;
  }

  static get joinAttrsKey() {
    return ['number', 'designation', 'percentage'];
  }

  static get listDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'journalType',
      'country',
      'active',
    ]);
  }

  static get browserDisplayAttrs() {
    return this.getAttributes([
      'number',
      'designation',
      'percentage',
      'journalType',
    ]);
  }

  static get allAttributes() {
    return {
      number: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        column: 'no',
        tableProperties: {
          header: 'hit-app.common.number',
          maxWidth: '1fr',
        },
      }),
      designation: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {required},
        tableProperties: {
          header: 'hit-base.common.designation',
          mainSearch: true,
          maxWidth: '1fr',
        },
      }),
      journalType: new HitContainerAttribute(ATTRIBUTE_TYPES.ENUMERATION, {
        column: 'journal_type',
        enumType: 'JOURNAL_TYPE',
        tableProperties: {
          header: 'hit-app.journal.journal-type',
          maxWidth: '1fr',
        },
      }),
      country: new HitContainerAttribute(ATTRIBUTE_TYPES.FK_COUNTRY, {
        joinPath: ['country!fk_tax_code_country_country'],
        tableProperties: {
          header: 'hit-base.common.country',
          maxWidth: '1fr',
        },
      }),
      peppolId: new HitContainerAttribute(ATTRIBUTE_TYPES.STRING, {
        validations: {max3Characters},
        column: 'peppol_id',
        tableProperties: {
          header: 'hit-app.common.peppol-id',
          maxWidth: '1fr',
        },
      }),
      active: new HitContainerAttribute(ATTRIBUTE_TYPES.BOOLEAN, {
        tableProperties: {
          propertyName: 'active',
          header: 'hit-app.common.active',
          maxWidth: '3.5rem',
        },
      }),
      percentage: new HitContainerAttribute(ATTRIBUTE_TYPES.DECIMAL, {
        column: 'percentage',
        validations: {required},
        decimalPrecision: 2,
        decimalDefaultValue: 0.0,
        tableProperties: {
          header: 'hit-app.tax-code.percentage',
          maxWidth: '1fr',
        },
      }),
      ...this.generateMultiLanguageField({
        key: 'legalClause',
        type: ATTRIBUTE_TYPES.STRING,
        header: 'hit-app.tax-code.legal-clause',
        column: 'legal_clause',
      }),
    };
  }
}
