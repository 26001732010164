<template>
  <hit-input-multi-language
    :values="multiLanguageValues"
    :locales="config.companyLanguagesPascalCase"
    :parse-locale-function="parseLocaleFunction"
    :enable-errors="showErrors"
    :validation-states="
      validate ? multiLanguageValidations : emptyValidationState
    "
    @change="handleChange"
  />
</template>
<script>
import {HitInputMultiLanguage} from '@hit/components';
import {useConfigurationStore} from '@hit/base';
import {parseLocale} from '@hit/base';
import {useI18n} from 'vue-i18n';

export default {
  name: 'HitContainerInputMultiLanguage',
  components: {
    HitInputMultiLanguage,
  },
  setup() {
    const {t} = useI18n();
    const config = useConfigurationStore();
    return {t, config};
  },
  props: {
    container: {
      type: Object,
      required: true,
    },
    attributesPrefix: {
      type: String,
      required: true,
    },
    validate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      configStore: useConfigurationStore(),
      parseLocaleFunction: parseLocale,
      valueSet: false,
    };
  },

  computed: {
    multiLanguageValues() {
      const values = {};
      this.config.companyLanguagesObject.forEach((lan) => {
        values[lan.valueSuffix] = this.container.data[
          `${this.attributesPrefix}${lan.valueSuffix}`
        ];
      });
      return values;
    },

    multiLanguageValidations() {
      const validations = {};
      this.config.companyLanguagesObject.forEach((lan) => {
        validations[lan.valueSuffix] =
          this.container?.validationState[
            `${this.attributesPrefix}${lan.valueSuffix}`
          ] ?? null;
      });
      return validations;
    },

    /**
     * Checks the ready flag of the container set when all the data has been processed
     */
    containerReady() {
      return this.container?.ready ?? false;
    },

    /**
     * Workaround to be sure that the data from the container has been loaded
     * into the input to avoid the flickering of the inputs
     */
    showErrors() {
      return this.validate && this.containerReady && this.valueSet;
    },

    /**
     * When the prop validate is set to false, we need to pass an empty validation object
     */
    emptyValidationState() {
      const validations = {};
      for (const lan in this.multiLanguageValues) {
        validations[lan] = null;
      }
      return validations;
    },
  },

  methods: {
    handleChange(locale, newValue) {
      this.container.handleChange(
        `${this.attributesPrefix}${locale}`,
        newValue
      );
    },
  },

  /**
   * Watchers used to avoid the flickering of the inputs during the init of the inputs
   */
  watch: {
    multiLanguageValues(newValue, oldValue) {
      this.valueSet = true;
    },
  },
};
</script>
