<template>
  <hit-entity-browser
    :title="
      onlyImages
        ? t('hit-base.common.browser-title-file')
        : t('hit-base.common.browser-title-img')
    "
    route="document"
    :table-attributes="attributesDoc"
    auto-complete-id="file"
    :data-list-config="dataListConfig"
    :default-sort="{property: 'filename', order: 'asc'}"
    @item-selected="selectItem"
  />
</template>
<script>
import HitEntityBrowser from './HitEntityBrowser.vue';
import {useI18n} from 'vue-i18n';
import FileDocument from '../../../models/hit/File';
export default {
  name: 'HitFileBrowser',
  components: {
    HitEntityBrowser,
  },
  setup() {
    const {t} = useI18n();
    return {t};
  },
  props: {
    onlyImages: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attributesDoc: FileDocument.browserDisplayAttrs,
      dataListConfig: {
        zone1Field: 'designation',
        zone2Field: 'filename',
        zone3Field: 'size',
      },
    };
  },
  methods: {
    selectItem(data) {
      const file = new FileDocument(data);
      this.$emit('itemSelected', file);
    },
  },
  emits: ['itemSelected'],
};
</script>
